import React, {Fragment, useState, useEffect} from "react";
import PopupWindow from "../../components/PopupWindow/PopupWindow";
import UnigowInput from "../../components/UnigowInput/UnigowInput";
import {Divider} from "@mui/material";
import UnigowButton from "../../components/UnigowButton/UnigowButton";
import {useAuth} from "../../contexts/AuthContext";
import {toast} from "react-toastify";
import {useAuthStore} from "../../stores/authStore";

function ForgotPass(): React.ReactElement {
    const {resetPassword} = useAuth();
    const {userData} = useAuthStore();

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(()=>{
        if (userData?.email) {
            setEmail(userData.email);
        }
    }, [userData, setEmail]);

    async function resetPass(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!email) {
            toast.error("Debes introducir un correo electrónico");
        }

        await resetPassword(email);

        setOpen(false);
    }

    return (
        <Fragment>
            <button type="button" className="text-ug-sm bg-transparent border-none text-primary-500 cursor-pointer hover:underline lg:text-left w-fit mx-auto lg:mx-0"
                onClick={()=>setOpen(true)}
            >¿Has olvidado tu contraseña?
            </button>
            <PopupWindow title="Recuperación de contraseña" closePopup={()=>setOpen(false)} open={open}>
                <form className="bg-white rounded-2xl flex flex-col gap-4 items-center" onSubmit={resetPass}>
                    <div className="flex justify-center">
                        <h2>Recuperación de contraseña</h2>
                    </div>
                    <Divider className="w-full"/>
                    <p className="text-ug-base text-center">Introduce el email con el que te registraste y te enviaremos un enlace para cambiar tu contraseña</p>
                    <div className="flex flex-col gap-3 w-full">
                        <h3>Tu correo electrónico</h3>
                        <UnigowInput inputProps={
                            {readOnly:userData !== null}
                        } required placeholder="Correo Electrónico" type="email" autoComplete="email"
                        value={email} onChange={(e)=>{
                            setEmail(e.target.value);
                        }}
                        />
                    </div>
                    <UnigowButton className="w-full" variant="primary">Enviar enlace</UnigowButton>
                </form>
            </PopupWindow>
        </Fragment>
    );
}

export default ForgotPass;