import React, {Children, FormEvent, KeyboardEvent, useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../../apis/requests";
import {useAuthStore} from "../../../../../stores/authStore";
import {WhatsappChat, WhatsappMessage, WhatsappTemplate} from "../../../../../types/chat";
import {Avatar, CircularProgress, Divider, IconButton, Skeleton, TextareaAutosize} from "@mui/material";
import {ArrowBackIosNew, Send} from "@mui/icons-material";
import {Helmet} from "react-helmet";
import {WhatsappMsg} from "../components/WhatsappMsg";
import {twMerge} from "tailwind-merge";
import {toast} from "react-toastify";
import {SendTemplate} from "../components/SendTemplate";

export default function AdminWhatsapp(): React.ReactElement {
    const navigate = useNavigate();

    const {userData} = useAuthStore();
    const {id} = useParams();

    const chatBox = useRef<HTMLUListElement>(null);

    const [currentMessage, setCurrentMessage] = useState("");
    const [messages, setMessages] = useState<WhatsappMessage[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [lastMessage, setLastMessage] = useState<WhatsappMessage | null>(null);
    const [canWrite, setCanWrite] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<WhatsappTemplate | null>(null);

    const {data:chatInfo, isLoading} = useQuery(["chat", id], async()=>{
        return api.get<WhatsappChat>(`chats/conversations/${id}`);
    }, {
        enabled:!!userData && userData.admin && !!id,
        onSuccess:(data)=>{
            if (!data.lastMessageDate) {
                setCanWrite(true);
            }

            // Check if its been more than 24 hours since the last message
            if (data.lastMessageDate) {
                const lastMessageDate = new Date(data.lastMessageDate);
                const now = new Date();

                const diff = now.getTime() - lastMessageDate.getTime();

                if (diff < 24 * 60 * 60 * 1000) {
                    setCanWrite(true);
                } else {
                    setCanWrite(false);
                }
            }
        }
    });

    const {data:templatesInfo = []} = useQuery("templates", async()=>{
        return api.get<WhatsappTemplate[]>("chats/conversations/templates");
    }, {
        enabled:!!userData && userData.admin
    });

    useQuery(["chatMessages", id, page], async()=>{
        return api.get<WhatsappMessage[]>(`chats/conversations/${id}/messages?page=${page}`);
    }, {
        enabled:!!chatInfo,
        onSuccess:(data)=>{
            if (data.length === 0) {
                setHasMore(false);
                return;
            }

            if (!lastMessage) {
                setLastMessage(data[0]);
            }

            // Append messages to the begginning of the array for pagination.
            setMessages([...data.reverse(), ...messages]);
        }}
    );

    /** This function loads new messages if the user scrolls to the top of the page */
    function handleScroll(e: React.UIEvent<HTMLElement>): void {
        if (e.currentTarget.scrollTop === 0 && hasMore) {
            setPage(page + 1);

            // Scroll to the last message seen
            if (chatBox.current && lastMessage) {
                const lastMessageEl = chatBox.current.querySelector("#message");
                if (lastMessageEl) {
                    lastMessageEl.scrollIntoView();
                }
            }
        }
    }

    async function sendMessage(e: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLTextAreaElement>): Promise<void> {
        e.preventDefault();

        const newMessage = {
            text: currentMessage
        };

        const response = await api.post<{text: string}, {status: string}>(`chats/conversations/${id}`, newMessage);

        // Append message to chat
        if (response) {
            const newMsg: WhatsappMessage = {
                body: currentMessage,
                sender: "unigow",
                sentDate: new Date()
            };
            setMessages([...messages, newMsg]);
            setCurrentMessage("");
            setLastMessage(newMsg);
        }
    }

    useEffect(()=>{
        if (chatBox.current) {
            chatBox.current.scrollTop = chatBox.current.scrollHeight;
        }
    }, [lastMessage]);

    useEffect(()=>{
        return ()=>{
            setMessages([]);
            setLastMessage(null);
        };
    }, []);

    useEffect(()=>{
        // Check every minute if the user can write (less than 24 hours since last message)
        const interval = setInterval(()=>{
            if (!chatInfo) return;

            if (!chatInfo.lastMessageDate) {
                setCanWrite(true);
                return;
            }

            const lastMessageDate = new Date(chatInfo.lastMessageDate);
            const now = new Date();

            const diff = now.getTime() - lastMessageDate.getTime();

            if (diff < 24 * 60 * 60 * 1000) {
                setCanWrite(true);
            } else {
                setCanWrite(false);
            }
        }, 60 * 1000);

        return ()=>clearInterval(interval);
    }, [chatInfo]);

    return (
        <div className="flex gap-4 w-full flex-col md:flex-row">
            {!!selectedTemplate && !!chatInfo && (
                <SendTemplate template={selectedTemplate} chat={chatInfo} closeModal={()=>setSelectedTemplate(null)}/>
            )}
            <div className="bg-white w-full lg:w-3/4 rounded-[10px]">
                <Helmet>
                    <title>Unigow - Whatsapp con {`${chatInfo?.user ? chatInfo.user.name : chatInfo?.phone}`}</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="flex items-center py-2 gap-2 px-4">
                    <div className="w-1/12 flex justify-center cursor-pointer" onClick={()=>navigate("/admin/whatsapp")}>
                        <ArrowBackIosNew/>
                    </div>
                    <div className="flex items-center mr-2">

                        <div className="justify-start flex rounded-full overflow-hidden flex-shrink-0">
                            {chatInfo?.user?.profilePic ? (
                                <img className="w-[50px] h-[50px]" src={chatInfo?.user?.profilePic} alt=""/>
                            ) : (
                                <Avatar/>
                            )}
                        </div>
                        <div className="ml-2 lg:ml-5 mr-2 w-full flex flex-col justify-start text-left leading-4 font-semibold gap-2">
                            {chatInfo ? <p className="font-semibold">{`${chatInfo?.user ? chatInfo.user.name : chatInfo?.phone}`}</p> : <Skeleton width="8ch"/>}
                            {/* {chatInfo?.user ?  <p className="text-gray-500 text-xs">Online
                            {formatTimeRelative(chatInfo?.user.lastLogin.date || new Date()).toLowerCase()}</p> : <Skeleton width="12ch"/>} */}
                        </div>
                    </div>
                </div>
                <Divider className="w-full"/>
                {userData && (
                    <ul className="flex flex-col gap-4 overflow-y-scroll p-4 h-[50vh]" ref={chatBox} onScroll={handleScroll}>
                        {!hasMore && (
                            <div className="w-full h-full flex justify-center items-center shadow-sm pb-2">
                                <p className="text-gray-cool-400">No hay más mensajes</p>
                            </div>
                        )}
                        {isLoading && (
                            <div className="w-full h-full flex justify-center items-center">
                                <CircularProgress className="" />
                            </div>
                        )}
                        {Children.toArray(messages.map((msg)=>(
                            <WhatsappMsg message={msg}/>
                        )))}
                    </ul>
                )}
                <form className="flex gap-4 py-4 px-4 items-center" onSubmit={sendMessage}>
                    <TextareaAutosize name="message" className="w-full bg-gray-cool-100 rounded-xl border-none text-gray-cool-500 text-ug-lg"
                        value={currentMessage} placeholder="Escribe tu texto" onChange={(e)=>{
                            setCurrentMessage(e.target.value);
                        }}
                        maxRows={4}
                        onKeyDown={(e)=>{
                            if (!canWrite) {
                                e.preventDefault();
                            }
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                void sendMessage(e);
                            }
                        }}
                        onClick={(e)=>{
                            if (!canWrite) {
                                e.preventDefault();
                                toast.error("No puedes enviar mensajes hasta que el usuario inicie una conversación.");
                            }
                        }}
                    />

                    <IconButton type="submit" className={twMerge("flex justify-center p-3 rounded-full border text-white", currentMessage.length === 0 ? "border-gray-300 bg-gray-300" : "border-primary-500 bg-primary-500 ")} disabled={currentMessage.length === 0}>
                        <Send/>
                    </IconButton>
                </form>
            </div>
            <div className="flex flex-col gap-4 grow bg-white py-4 px-2 rounded-xl">
                <p className="text-lg font-semibold text-center">Plantillas</p>
                <ul className="list-none h-[60vh] flex flex-col gap-4 overflow-y-auto">
                    {Children.toArray(templatesInfo.map((template)=>(
                        <li className="py-4 px-2 rounded-md shadow-lg text-center border border-gray-200 border-solid cursor-pointer hover:bg-gray-100 transition-colors" onClick={()=>{
                            setSelectedTemplate(template);
                        }}
                        >{template.name}
                        </li>
                    )))}
                </ul>
            </div>
        </div>
    );
}