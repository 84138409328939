import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import {joinGroupHelper, userAnsweredQuestions} from "@unigow/helpers/chat";
import {getPluginData} from "@unigow/helpers/iframe";
import {useIframe} from "@unigow/pages/Iframe/contexts/IframeContext";
import {useAuthStore} from "@unigow/stores/authStore";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {DisplayGroup} from "@unigow/types/group";
import {GroupsPluginType} from "@unigow/unigow-types";
import React from "react";

interface JoinGroupButtonProps {
    currGroup: DisplayGroup
}

export default function JoinGroupButton({currGroup}: JoinGroupButtonProps): React.ReactElement {
    const {getRequiredParams} = useCustomLocale();
    const {iframeData, setPendingAction} = useIframeStore();
    const {userData} = useAuthStore();
    const {setCurrentPage} = useIframe();

    async function joinGroup(): Promise<void> {
        if (!iframeData) return;

        const pluginData = getPluginData(currGroup.plugin, iframeData.pluginData) as GroupsPluginType;

        if (!pluginData) return;

        if (!userData) {
            localStorage.setItem("redirectTo", "form");
            setCurrentPage("register");
            return;
        }

        const unansweredQuestions = userAnsweredQuestions(getRequiredParams, userData.minor,  pluginData.settings.userParams,
            userData?.additionalInfo);

        if (unansweredQuestions.length > 0 || !userData || (pluginData.settings.userNeedsPhone && (!userData.phone || userData.phone === ""))) {
            setPendingAction({
                action:"group",
                data:currGroup,
                needsPhone:pluginData.settings.userNeedsPhone,
                userParams:pluginData.settings.userParams,
                privacy: iframeData.clientInfo.privacy ? {
                    privacyLink:iframeData.clientInfo.privacy,
                    clientName:iframeData.clientInfo.name,
                    clientId:iframeData.clientInfo._id.toString()
                } : undefined
            });

            if (!userData) {
                localStorage.setItem("redirectTo", "form");
                setCurrentPage("register");
            } else {
                setCurrentPage("form");
            }
            return;
        }

        await joinGroupHelper(currGroup, (v)=>setCurrentPage(`group-${v}`));
    }

    return (
        <UnigowButton className="w-full" variant="primary" onClick={joinGroup}>Unirme al grupo
        </UnigowButton>
    );
}