import {CircularProgress} from "@mui/material";
import {Message, Paginator, Participant, ParticipantUpdateReason} from "@twilio/conversations";
import MessageItem from "@unigow/components/ChatRoom/components/MessageItem";
import {useChatStore} from "@unigow/components/ChatRoom/store/chatStore";
import {useTwilio} from "@unigow/contexts/TwilioContext";
import {useAuthStore} from "@unigow/stores/authStore";
import {TwilioChat, UnigowMessage} from "@unigow/types/chat";
import React, {useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import {twMerge} from "tailwind-merge";

interface ChatBoxProps {
    // Mongo info about the chat
    chatInfo: TwilioChat;
    // Needed for the messageItem component (inherited from the grouproom, it defines if the chat is a group chat)
    group?: boolean;
}

function analyzeMessages(messages: Message[], prevAutor?: string | null): UnigowMessage[] {
    return messages.map((msg, i)=>{
        if (!msg.body && (!msg.attachedMedia || msg.attachedMedia.length === 0)) return new UnigowMessage(false, msg);

        // Ver si el mensaje tiene valores en sus atributos
        if (msg.attributes && typeof msg.attributes === "object") {
            try {
                const parsedAttributes = msg.attributes as Record<string, unknown>;

                let showName = true;

                if (messages.length > 1 && !prevAutor) {
                    const prevMsg = messages[i - 1];
                    if (prevMsg && prevMsg.author === msg.author) {
                        showName = false;
                    }
                }

                // Check if the previous message is from the same user
                if (prevAutor && msg.author === prevAutor) {
                    showName = false;
                }

                if (parsedAttributes.displayName) {
                    const newMsg = new UnigowMessage(false, msg);
                    newMsg.mod = parsedAttributes.mod as boolean;
                    newMsg.answersTo = parsedAttributes.answersTo as string | undefined;
                    if (showName) {
                        newMsg.displayName = parsedAttributes.displayName as string;
                    }
                    return newMsg;
                }
            } catch (err) {
                console.log(err);
                // Do nothing
            }
        }

        return new UnigowMessage(false, msg);
    });
}

export default function ChatBox({chatInfo, group}: ChatBoxProps): React.ReactElement {
    const {conv, showPinnedQuestions} = useChatStore();
    const {userData} = useAuthStore();
    const {twilioClient} = useTwilio();

    const [messages, setMessages] = useState<UnigowMessage[]>([]);
    const [messagePaginator, setMessagePaginator] = useState<Paginator<Message>>();
    const [lastReadMessage, setLastReadMessage] = useState<number>(-1);

    const {isLoading} = useQuery(["messages", chatInfo.SID], async()=>{
        const msgs = await conv!.getMessages(10);

        // if (msgs.items.length === 0 && chatInfo.type === "chat" && chatInfo?.position === "orientated") {
        //     setIsFirstMessage(true);
        // }

        setMessagePaginator(msgs);

        return msgs?.items;
    }, {
        enabled:!!conv,
        onSuccess: async(msgs)=>{
            const analyzedMsgs = analyzeMessages(msgs);
            setMessages(analyzedMsgs);
            if (chatInfo.type === "chat") {
                const otherUser = await conv!.getParticipantByIdentity(chatInfo?.user._id);
                if (otherUser) {
                    const otherRead = otherUser.lastReadMessageIndex || -1;
                    setLastReadMessage(otherRead);
                }
            }
        }
    });

    const chatBox = useRef<HTMLUListElement>(null);

    // EFFECTS
    useEffect(()=>{
        function handleNewMessage(message: Message): void {
            if (!!conv && message.conversation.sid === conv.sid) {
                void conv.setAllMessagesRead();
                const prevMessage = messages[messages.length - 1];

                const analyzedMsg = analyzeMessages([message], prevMessage ? prevMessage.twilioMsg.author : undefined);
                setMessages([...messages, ...analyzedMsg]);
                if (chatBox.current) {
                    chatBox.current.scrollTop = chatBox.current.scrollHeight;
                }
            }
        }

        function handleParticipantRead(data: {participant: Participant, updateReasons: ParticipantUpdateReason[]}): void {
            if (data.participant.identity !== userData?._id && data.updateReasons.includes("lastReadMessageIndex")) {
                // Other user has read the messages
                setLastReadMessage(data.participant.lastReadMessageIndex || 0);
            }
        }

        function updateMessage(data: {message: Message}): void {
            const newMessages = messages.map((msg)=>{
                if (msg.twilioMsg.sid === data.message.sid) {
                    const newMsg = new UnigowMessage(msg.mod, msg.twilioMsg);

                    return newMsg;
                }
                return msg;
            });

            setMessages(newMessages);
        }

        function handleHaveIBeenKicked(participant: Participant): void {
            if (participant.identity === userData?._id) {
                toast.info("Has abandonado el grupo");
                window.location.reload();
            }
        }

        const event = twilioClient?.on("messageAdded", handleNewMessage);
        const readMessages = twilioClient?.on("participantUpdated", handleParticipantRead);
        const kicked = twilioClient?.on("participantLeft", handleHaveIBeenKicked);
        const messageChanged = twilioClient?.on("messageUpdated", updateMessage);

        return ()=>{
            event?.removeListener("messageAdded", handleNewMessage);
            readMessages?.removeListener("participantUpdated", handleParticipantRead);
            messageChanged?.removeListener("messageUpdated", updateMessage);
            kicked?.removeListener("participantLeft", handleHaveIBeenKicked);
        };
    }, [messages, twilioClient, conv, userData]);

    useEffect(()=>{
        if (chatBox.current) {
            chatBox.current.scrollTop = chatBox.current.scrollHeight;
        }
    }, [messages, conv]);

    /** This function loads new messages if the user scrolls to the top of the page */
    async function handleScroll(e: React.UIEvent<HTMLElement>): Promise<void> {
        if (e.currentTarget.scrollTop === 0) {
            if (messagePaginator?.hasPrevPage) {
                const newMessages = await messagePaginator?.prevPage();
                setMessagePaginator(newMessages);
                if (newMessages?.items) {
                    const analyzedMsgs = analyzeMessages(newMessages.items);
                    setMessages([...analyzedMsgs, ...messages]);
                }
            }
        }
    }

    return (
        <ul className={twMerge("flex chatbox flex-col gap-4 overflow-y-scroll p-4 h-[50vh]", group ? "pt-[90px]" : "", showPinnedQuestions.length > 0 ? "overflow-hidden" : "")} ref={chatBox} onScroll={handleScroll}>
            {isLoading && (
                <div className="w-full h-full flex justify-center items-center">
                    <CircularProgress className="" />
                </div>
            )}
            {!!userData && messages.map((message)=>(
                <MessageItem isGroup={group} unigowMessage={message} key={message.twilioMsg.sid} userId={userData._id}
                    read={lastReadMessage >= message.twilioMsg.index}
                    unigowMsg={chatInfo.type === "chat" && message.twilioMsg.author !== userData._id && message.twilioMsg.author !== chatInfo.user._id && chatInfo.position === "orientator"}
                />
            ))}
        </ul>
    );
}