import React, {useEffect} from "react";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {useQuery} from "react-query";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {IframeAPI} from "@unigow/apis/iframe";
import {useIframeWizardStore} from "./stores/iframeWizard.store";
import {Outlet} from "react-router-dom";
import {ClientAPI} from "@unigow/apis/client";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import {usePopupStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/popup.store";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";

export default function Iframes(): React.ReactElement {
    const {selectedClient, clientInfo, setClientParams} = useDashboardStore();
    const {setClientIframes, iframeData} = useIframeWizardStore();
    const {resetPluginData:resetChats} = useChatsPluginStore();
    const {resetPluginData:resetGroups} = useGroupWizardStore();
    const {resetPluginData:resetCards} = useCardWizardStore();
    const {resetPopupData} = usePopupStore();

    useQuery(["iframes", selectedClient], async()=>{
        return IframeAPI.getClientIframes({clientId:selectedClient!});
    }, {enabled:!!selectedClient && !!clientInfo && clientInfo.permissions?.manager,
        onSuccess:(data)=>{
            setClientIframes(data);
        }
    });

    useQuery(["params", clientInfo?._id], async()=>{
        return ClientAPI.getClientParams({clientId:clientInfo!._id.toString()});
    }, {enabled:!!clientInfo,
        onSuccess:((res)=>{
            setClientParams(res);
        })
    });

    useEffect(()=>{
        resetChats();
        resetGroups();
        resetPopupData();
        resetCards();
    }, [iframeData?._id, resetChats, resetGroups, resetPopupData, resetCards]);

    return (
        <PageContainer className="items-start h-[calc(100%-1.5rem)] pb-0 px-0 lg:px-0 gap-4">
            <Outlet/>
        </PageContainer>
    );
}