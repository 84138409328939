import React, {Fragment, useEffect, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {ReactComponent as LargeLogo} from "@unigow/assets/unigow-black.svg";
import {ReactComponent as SmallLogo} from "@unigow/assets/unigow-small.svg";
import SidebarItem from "./SidebarItem";
import {GiftIcon, ChatBubbleLeftIcon, Cog6ToothIcon, EnvelopeIcon, FingerPrintIcon, PresentationChartLineIcon, UserGroupIcon, ChatBubbleLeftRightIcon, LockClosedIcon, AcademicCapIcon} from "@heroicons/react/24/outline";
import {ReactComponent as DashboardIcon} from "@unigow/assets/dashboard.svg";
import {ReactComponent as SupportIcon} from "@unigow/assets/support.svg";
import {ReactComponent as Logout} from "@unigow/assets/logout.svg";
import {ReactComponent as Toggle} from "@unigow/assets/toggle-menu.svg";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";
import {useDashboardStore} from "../../../stores/dashboard/dashboardStore";
import useUnigowStore from "../../../stores/unigowStore";
import {IconButton, Skeleton} from "@mui/material";
import {useMediaQuery} from "react-responsive";
import {useAuthStore} from "../../../stores/authStore";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";

interface SidebarProps {
    folded: boolean;
    setFolded: (v: React.SetStateAction<boolean>)=> void;
    loadingPermissions: boolean;
}

function Sidebar({folded, setFolded, loadingPermissions}: SidebarProps): React.ReactElement {
    const {clientInfo} = useDashboardStore();
    const {userData} = useAuthStore();

    const {openSupport} = useUnigowStore();
    const {logoutUser} = useAuth();
    const navigate = useNavigate();

    const isMobile = useMediaQuery({maxWidth:"768px"});

    const sidebarContent = useRef<HTMLUListElement>(null);

    const [isScrolledToTop, setIsScrolledToTop] = useState(true);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

    useEffect(() => {
        if (loadingPermissions) return;

        const sidebar = sidebarContent.current;

        const handleScroll = (): void => {
            if (sidebar) {
                const {scrollTop, scrollHeight, clientHeight} = sidebar;
                setIsScrolledToTop(scrollTop < 5);
                setIsScrolledToBottom(scrollTop + clientHeight >= (scrollHeight * 0.98));
            }
        };

        if (sidebar) {
            sidebar.addEventListener("scroll", handleScroll);
            // Execute also on resize
            window.addEventListener("resize", handleScroll);
            handleScroll();
        }

        return () => {
            if (sidebar) {
                sidebar.removeEventListener("scroll", handleScroll);
                window.removeEventListener("resize", handleScroll);
            }
        };
    }, [loadingPermissions]);

    return (
        <div className={twMerge("h-[100svh] shadow-ug-xl bg-white z-10 flex-shrink-0 fixed flex flex-col justify-evenly", folded ? "w-20" : "w-64 px-6")} style={{left:isMobile ? "calc(var(--sidebar-width) * -1)" : 0}}>
            <div className={twMerge("flex h-[43px] py-4", folded ? "justify-center" : "")}>
                {folded ? (
                    <SmallLogo/>
                ) : (
                    <LargeLogo/>
                )}
            </div>
            {!loadingPermissions ? (
                <div className="relative">
                    <ul className={twMerge("relative flex flex-col gap-3 h-[calc(100svh-200px)] overflow-y-auto scrollbar-hide", folded ? "items-center" : "")} ref={sidebarContent}>
                        {userData?.admin && (
                            <SidebarItem folded={folded} Icon={LockClosedIcon} label="Administración"
                                active={window.location.pathname.includes("/admin")}
                                onClick={()=>navigate("/admin")}
                            />
                        )}
                        {clientInfo?.permissions?.orientator && (
                            <Fragment>
                                <SidebarItem folded={folded} Icon={ChatBubbleLeftIcon} label="Chats"
                                    active={window.location.pathname === "/" || window.location.pathname === "/chats"}
                                    onClick={()=>navigate("/chats")}
                                />
                                {clientInfo?.incentives && (
                                    <SidebarItem folded={folded} Icon={GiftIcon} label="Recompensas"
                                        active={window.location.pathname === "/" || window.location.pathname === "/awards"}
                                        onClick={()=>navigate("/awards")}
                                    />
                                )}
                                {clientInfo.questionary && (
                                    <SidebarItem folded={folded} Icon={AcademicCapIcon} label="Mi opinión"
                                        active={window.location.pathname === "/" || window.location.pathname === "/retos"}
                                        onClick={()=>navigate("/retos")}
                                    />
                                )}
                            </Fragment>
                        )}
                        {clientInfo?.permissions?.manager && (
                            <Fragment>
                                <SidebarItem folded={folded} Icon={FingerPrintIcon} label="Configuración"
                                    active={window.location.pathname === "/" || window.location.pathname.includes("/iframes")}
                                    onClick={()=>navigate("/iframes")}
                                />
                                {/* {(window.location.pathname === "/" || window.location.pathname.includes("/startup")) && (
                                    <Fragment>
                                        <SidebarItem folded={folded} Icon={AdjustmentsVerticalIcon} label="Configurar Conector"
                                            active={window.location.pathname.includes("iframewizard")}
                                            onClick={()=>navigate("/startup/iframewizard")}
                                            secondary
                                        />
                                        <SidebarItem folded={folded} Icon={CursorArrowRaysIcon} label="Configurar Reminder"
                                            active={window.location.pathname.includes("reminderwizard")}
                                            onClick={()=>navigate("/startup/reminderwizard")}
                                            secondary
                                        />
                                        <SidebarItem folded={folded} Icon={SquaresPlusIcon} label="Crear Landing Page"
                                            active={window.location.pathname.includes("landingwizard")}
                                            onClick={()=>navigate("/startup/landingwizard")}
                                            secondary
                                        />
                                        {clientInfo.groups && (
                                            <SidebarItem folded={folded} Icon={ChatBubbleLeftEllipsisIcon} label="Configurar Grupos"
                                                active={window.location.pathname.includes("groupswizard")}
                                                onClick={()=>navigate("/startup/groupswizard")}
                                                secondary
                                            />
                                        )}
                                    </Fragment>
                                )} */}
                                <SidebarItem folded={folded} Icon={DashboardIcon} label="Estadísticas"
                                    active={window.location.pathname === "/stats"}
                                    onClick={()=>navigate("/stats")}
                                />
                                <SidebarItem folded={folded} Icon={PresentationChartLineIcon} label="Actividad"
                                    active={window.location.pathname === "/activity"}
                                    onClick={()=>navigate("/activity")}
                                />
                                <SidebarItem folded={folded} Icon={EnvelopeIcon} label="Leads Únicos"
                                    active={window.location.pathname === "/leads"}
                                    onClick={()=>navigate("/leads")}
                                />
                                <SidebarItem folded={folded} Icon={ChatBubbleLeftRightIcon} label="Conversaciones"
                                    active={window.location.pathname === "/analysis"}
                                    onClick={()=>navigate("/analysis")}
                                    newFeature
                                />
                                <SidebarItem folded={folded} Icon={UserGroupIcon} label="Embajadores"
                                    active={window.location.pathname === "/ambassadors"}
                                    onClick={()=>navigate("/ambassadors")}
                                />
                            </Fragment>
                        )}
                        <SidebarItem folded={folded} Icon={SupportIcon} label="Soporte"
                            active={window.location.pathname === "/support"}
                            onClick={()=>{
                                openSupport();
                            }}
                        />
                        <SidebarItem folded={folded} Icon={Cog6ToothIcon} label="Ajustes Cuenta"
                            active={window.location.pathname === "/account"}
                            onClick={()=>navigate("/account")}
                        />
                        <SidebarItem folded={folded} Icon={Logout} label="Cerrar Sesión"
                            onClick={()=>void logoutUser({redirect:"/login"})}
                        />
                    </ul>
                    {!isScrolledToTop && (
                    // Small blur in case there is need to scroll
                        <div className="absolute top-0 left-0 w-full h-8 bg-gradient-to-b from-gray-200 to-transparent rounded-t-lg flex justify-center">
                            <IconButton size="small" onClick={()=>{
                                sidebarContent.current?.scrollTo({top:0, behavior:"smooth"});
                            }}
                            >
                                <KeyboardArrowUp/>
                            </IconButton>
                        </div>
                    )}
                    {!isScrolledToBottom && (
                    // Small blur in case there is need to scroll
                        <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-gray-200 to-transparent rounded-b-lg flex justify-center">
                            <IconButton size="small" onClick={()=>{
                                sidebarContent.current?.scrollTo({top:sidebarContent.current.scrollHeight, behavior:"smooth"});
                            }}
                            >
                                <KeyboardArrowDown/>
                            </IconButton>
                        </div>
                    )}
                </div>
            ) : (
                <div className={twMerge("flex flex-col gap-3", folded ? "items-center" : "")}>
                    <Skeleton variant={folded ? "circular" : "rounded"} height={33} width={folded ? 33 : undefined}/>
                    <Skeleton variant={folded ? "circular" : "rounded"} height={33} width={folded ? 33 : undefined}/>
                    <Skeleton variant={folded ? "circular" : "rounded"} height={33} width={folded ? 33 : undefined}/>
                    <Skeleton variant={folded ? "circular" : "rounded"} height={33} width={folded ? 33 : undefined}/>
                    <Skeleton variant={folded ? "circular" : "rounded"} height={33} width={folded ? 33 : undefined}/>
                </div>
            )}

            {!isMobile && (
                <div className={twMerge("h-[50px] flex items-center", folded ? "justify-center" : "")}>
                    <button className="h-fit bg-transparent border-none cursor-pointer text-ug-sm text-gray-cool-400 flex gap-2" onClick={()=>{
                        setFolded((prev)=>!prev);
                    }}
                    >
                        <Toggle/>
                        {!folded && (
                            <p>Plegar Sidebar</p>
                        )}
                    </button>
                </div>
            )}
        </div>
    );
}

export default Sidebar;
