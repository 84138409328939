import {api} from "@unigow/apis/requests";
import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import {CardsPluginType} from "@unigow/unigow-types";
import React, {useEffect, useState} from "react";
import {useQueryClient} from "react-query";
import {toast} from "react-toastify";

export default function CardPluginConfig(): React.ReactElement {
    const queryClient = useQueryClient();

    const {iframeData} = useIframeWizardStore();
    const {cardPageData, cardPageCards, setConfigPage, selectCardPage, setCardPageData} = useCardWizardStore();

    const [alias, setAlias] = useState("");
    // const [format, setFormat] = useState(cardPageData?.style.format || "icon");
    const [buttonText, setButtonText] = useState("Quiero inscribirme");
    const [label, setLabel] = useState("Actividades");

    useEffect(()=>{
        if (cardPageData) {
            setAlias(cardPageData.alias);
            setButtonText(cardPageData.style.buttonText);
            setLabel(cardPageData.style.label || "Actividades");
        }
    }, [cardPageData]);

    async function saveChanges(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (alias === "") {
            toast.error("Debes seleccionar un nombre para el plugin de tarjetas");
            return;
        }

        const group: Pick<CardsPluginType, "alias" | "style"> = {
            alias,
            style:{
                buttonText,
                format: "icon",
                label
            }
        };

        let response: CardsPluginType | undefined = undefined;

        if (cardPageData) {
            response = await api.patch<typeof group, CardsPluginType>(`plugincards/${cardPageData._id}`, group);
        } else {
            response = await api.post<typeof group, CardsPluginType>(`iframes/${iframeData?._id}/plugins/cards`, group);
        }

        if (response) {
            if (!cardPageData) {
                // Es un grupo nuevo, seleccionarlo
                selectCardPage(response._id.toString());

                setConfigPage("cards");
            }

            setCardPageData(response);
            // Invalidate data
            await queryClient.invalidateQueries(["cards", response._id]);

            setConfigPage("cards");
        }

    }

    return (
        <div className="flex gap-8 flex-col lg:flex-row w-full">
            <form className="flex flex-col lg:w-[55%] w-full shrink-0 gap-[14px] p-4 border border-gray-cool-300 border-solid rounded-xl" onSubmit={saveChanges}>
                <div className="flex flex-col gap-2">
                    <h2 className="text-[24px] font-semibold">Configuración básica</h2>
                    <p className="text-sm font-light">Estás a punto de terminar! Solo falta que cambies algún detalle si lo ves necesario
                    </p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-semibold">Escribe un identificador</p>
                    {/* <p className="font-light text-ug-sm">Puedes mantener el identificador predeterminado</p> */}
                    <UnigowInput required value={alias} onChange={(e)=>setAlias(e.target.value)} placeholder="Por ejemplo: &quot;Actividad en el parque&quot;"/>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-semibold">Título del plugin</p>
                    <p className="font-light text-ug-sm">Este será el texto que aparezca en la parte superior del iframe</p>
                    <UnigowInput required value={label} onChange={(e)=>setLabel(e.target.value)} placeholder="Por ejemplo: &quot;Actividades&quot;"/>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-semibold">Texto del botón de inscripción</p>
                    <p className="font-light text-ug-sm">Por ejemplo: &quot;Quiero inscribirme&quot;</p>
                    <UnigowInput required value={buttonText} onChange={(e)=>setButtonText(e.target.value)} placeholder="Por ejemplo: &quot;Quiero inscribirme&quot;"/>
                </div>
                <div className="flex gap-[10px] flex-col lg:flex-row">
                    <UnigowButton variant="secondary" type="submit">Continuar con la activación de tarjetas</UnigowButton>
                </div>
            </form>
            <div className="flex items-center text-left flex-grow flex-col">
                <div className="flex justify-between w-full items-center mb-2">
                    <p className="text-xs font-semibold">Vista previa</p>
                    {cardPageData?.alias && (
                        <UnigowButton className="py-0 text-xs" variant="basic" onClick={()=>{
                            setConfigPage("cards");
                        }}
                        >Editar o crear tarjetas
                        </UnigowButton>
                    )}
                </div>
                <div className="w-full">
                    {cardPageCards && cardPageCards.length > 0 ? (
                        <CardContainer>
                            <CardTop color={iframeData?.style.mainColor} name={cardPageCards[0].name}
                                iconUrl={cardPageCards[0].iconUrl}
                            />

                            <CardBottom fields={cardPageCards[0].fields}/>

                            <div className="px-4">
                                <UnigowButton buttonType="button" ref={(node)=>{
                                    if (node) {
                                        node.style.setProperty("background-color", iframeData?.style.mainColor || "", "important");
                                    }
                                }} className={"w-full"} variant="primary"
                                >{buttonText}
                                </UnigowButton>
                            </div>
                        </CardContainer>
                    ) : (
                        <CardContainer>
                            <CardTop name="Título de la actividad" iconUrl=""/>

                            <CardBottom hasDescription description="Aquí podrás escribir una descripción atractiva para animar a que las personas entren" fields={[]}/>

                            <div className="px-4">
                                <UnigowButton className="w-full" variant="primary">{buttonText}</UnigowButton>
                            </div>
                        </CardContainer>
                    )}
                </div>
            </div>
        </div>
    );
}