import React, {LiHTMLAttributes, MouseEvent, useState} from "react";
import {ReactComponent as AvatarOdd} from "@unigow/assets/avatarA.svg";
import {ReactComponent as AvatarEven} from "@unigow/assets/avatarB.svg";
import {formatTime} from "../../../helpers/helper";
import {IconButton} from "@mui/material";
import {Check, Close} from "@mui/icons-material";
import {api} from "@unigow/apis/requests";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {twMerge} from "tailwind-merge";

interface ChatItemProps extends LiHTMLAttributes<HTMLLIElement> {
    chatItem: {
        _id: string;
        SID?: string,
        name: string;
        iconUrl: string;
        lastMessage?: {
            body: string;
            added?: Date | null;
        } | undefined;
        unread?: number;
        pending?: boolean;
    }
    index: number;
}

export function ChatItem(props: ChatItemProps): React.ReactElement {
    const {chatItem, index, ...liProps} = props;
    const [show, setShow] = useState(true);

    const navigate = useNavigate();

    function renderImage(): React.ReactElement {
        if (chatItem.iconUrl && chatItem.iconUrl !== "") {
            return <img className="w-full" src={chatItem.iconUrl} alt="" />;
        } else if (index % 2 === 0) {
            return <AvatarEven/>;
        }
        return <AvatarOdd/>;
    }

    async function answerInvitation(e: MouseEvent<HTMLButtonElement>, answer: "accept" | "reject"): Promise<void> {
        e.stopPropagation();
        const response = await api.post<void, {status: string}>(`groups/${chatItem._id}/${answer}`);

        if (response.status === "ok") {
            if (answer === "reject") {
                toast.success("Invitación rechazada");
                setShow(false);

                return;
            } else if (answer === "accept") {
            // Go to chat
                navigate(`/chats?group=${chatItem.SID}`);
                toast.success("Invitación aceptada");
            }
            return;
        }
        toast.error("Error al responder la invitación");
        return;
    }

    if (!show) return (<></>);

    return (
        <li {...liProps} className={twMerge("flex relative gap-5 px-[30px] py-[15px] items-center cursor-pointer flex-grow justify-between", liProps.className)}>
            <div className="flex gap-2 items-center">
                <div className="flex-shrink-0 w-[50px] h-[50px] rounded-full overflow-hidden items-center flex">
                    {renderImage()}
                </div>
                <div className="flex flex-col lg:w-1/2">
                    <p className="text-ug-lg font-semibold">{chatItem.name}</p>
                    <p className="text-ug-lg text-ellipsis line-clamp-1">{chatItem.lastMessage?.body}</p>
                </div>
            </div>
            {chatItem.pending ? (
                <div className="flex gap-2">
                    <IconButton color="success" onClick={(e)=>answerInvitation(e, "accept")}><Check/></IconButton>
                    <IconButton color="error" onClick={(e)=>answerInvitation(e, "reject")}><Close/></IconButton>
                </div>
            ) : (
                <div className="absolute top-0 right-0 pr-0 pt-[15px] text-gray-cool-400 text-ug-sm flex gap-4">
                    <div className="flex flex-col gap-2">
                        <p>{formatTime(chatItem.lastMessage?.added, "short")}</p>
                        {chatItem.unread && chatItem.unread > 0 ? (
                            <div className="bg-primary-500 text-white rounded-full w-[20px] h-[20px] flex justify-center items-center self-end font-semibold">
                                <p className="text-ug-sm">{chatItem.unread}</p>
                            </div>
                        ) : <></>}
                    </div>
                </div>
            )}
        </li>
    );
}