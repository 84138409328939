import {LinearProgress} from "@mui/material";
import SlotsConfig from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/steps/SlotsConfig";
import CardsStep1 from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/steps/Step1";
import CardsStep2 from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/steps/Step2";
import React, {useState} from "react";

type CardPluginStep = "basic" | "userparams" | "slots";

export interface EditCardProps {
    setStep: (step: CardPluginStep)=> void;
}

export default function EditCard(): React.ReactElement {
    const [step, setStep] = useState<CardPluginStep>("basic");

    return (
        <div className="flex flex-col gap-4">
            <LinearProgress className="bg-[#E0EDFF] rounded-lg" variant="determinate" value={step === "basic" ? 0 : 50}/>
            {step === "basic" && (
                <CardsStep1 setStep={setStep}/>
            )}
            {step === "userparams" && (
                <CardsStep2 setStep={setStep}/>
            )}
            {step === "slots" && (
                <SlotsConfig setStep={setStep}/>
            )}
        </div>
    );
}