import {create} from "zustand";
import {LoggedUser} from "../types/user";

interface AuthState {
    // Información del usuario logueado
    userData: LoggedUser | null,

    // Función para modificar la información del usuario logueado
    setUserData: (userData: LoggedUser | null)=> void;

    // Función para modificar propiedad de la información del usuario logueado siguiendo los tipos de LoggedUser
    modifyUserData: <K extends keyof LoggedUser>(property: K, value: LoggedUser[K])=> void;

    // Boolean de carga
    loading: boolean;

    // Función para modificar el estado de carga
    setLoading: (loading: boolean)=> void;
}

export const useAuthStore = create<AuthState>((set, get) => ({
    userData:null,
    setUserData:(userData)=>{
        set({userData});
    },
    loading:true,
    setLoading:(loading)=>set({loading}),
    modifyUserData:(property, value)=>{
        const {userData} = get();
        if (!userData) return;

        const updatedUserData = {
            ...userData,
            [property]: value
        };

        set({userData:updatedUserData});
    }
}));