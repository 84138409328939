import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import {joinActivityHelper} from "@unigow/helpers/cards";
import {userAnsweredQuestions} from "@unigow/helpers/chat";
import {getPluginData} from "@unigow/helpers/iframe";
import {useIframe} from "@unigow/pages/Iframe/contexts/IframeContext";
import {useAuthStore} from "@unigow/stores/authStore";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {CardsPluginType, CardType} from "@unigow/unigow-types";
import React from "react";
import {toast} from "react-toastify";

interface JoinCardButtonProps {
    currCard: CardType & {participantNumber: number};
}

export default function JoinActivityButton({currCard}: JoinCardButtonProps): React.ReactElement {
    const {getRequiredParams} = useCustomLocale();
    const {iframeData, setPendingAction} = useIframeStore();
    const {userData} = useAuthStore();
    const {setCurrentPage} = useIframe();

    if (!iframeData) return <></>;

    const isFull = currCard.participantNumber >= currCard.maxParticipants;

    const startDate = currCard?.startReservationDate && currCard.type === "reservations" ?
        new Date(currCard.startReservationDate) : undefined;

    if (startDate) startDate.setSeconds(0);

    const inJoinPeriod = startDate ? startDate.getTime() <= Date.now() : true;

    const pluginData = getPluginData(currCard.plugin.toString(), iframeData.pluginData) as CardsPluginType;

    async function joinCard(): Promise<void> {
        if (!iframeData) return;

        if (isFull) {
            toast.error("This activity is full");
            return;
        }

        if (!inJoinPeriod) {
            toast.error(`This activity will be available from ${startDate?.toLocaleDateString()} at ${startDate?.toLocaleTimeString()}`);
            return;
        }

        setPendingAction({
            action:"card",
            data:currCard,
            needsPhone:currCard.settings.userNeedsPhone,
            userParams:currCard.settings.userParams,
            privacy: iframeData.clientInfo.privacy ? {
                privacyLink:iframeData.clientInfo.privacy,
                clientName:iframeData.clientInfo.name,
                clientId:iframeData.clientInfo._id.toString()
            } : undefined
        });

        if (!userData) {
            localStorage.setItem("redirectTo", "form");
            setCurrentPage("register");
            return;
        }

        // Check minor status
        if (iframeData.settings.ageRestriction === "minors" && !userData.minor) {
            toast.error("Esta actividad es solo para menores de edad");
            return;
        } else if (iframeData.settings.ageRestriction === "adults" && userData.minor) {
            toast.error("Esta actividad es solo para mayores de edad");
            return;
        }

        const unansweredQuestions = userAnsweredQuestions(getRequiredParams, userData.minor,  currCard.settings.userParams, userData?.additionalInfo);

        if (unansweredQuestions.length > 0 || (currCard.settings.userNeedsPhone && (!userData.phone || userData.phone === ""))) {
            setCurrentPage("form");
            return;
        }

        // Function to join activity
        await joinActivityHelper(currCard, setCurrentPage, ()=>setPendingAction(undefined));
    }

    function getButtonText(): string {
        return isFull ? "Actividad completa" : pluginData?.style.buttonText;
    }

    return (
        <UnigowButton disabled={isFull || !inJoinPeriod} className="w-full" variant="primary" onClick={joinCard}>
            {inJoinPeriod ? getButtonText() : `Disponible a partir del ${startDate?.toLocaleDateString()} a las ${startDate?.toLocaleTimeString()}`}
        </UnigowButton>
    );
}