import {api} from "@unigow/apis/requests";
import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import ExistingMentorSelector from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/sections/CreateGroups/ExistingMentorSelector";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import MentorsInvite from "@unigow/pages/Dashboard/pages/Mentors/components/MentorsInvite";
import {FullGroup, NewGroup} from "@unigow/types/group";
import React, {Fragment, useState} from "react";
import {useQueryClient} from "react-query";
import {toast} from "react-toastify";

interface EditGroupProps {
    group: NewGroup | FullGroup;
}

export default function EditGroup({group}: EditGroupProps): React.ReactElement {
    const {iframeData} = useIframeWizardStore();
    const {selectedGroupPage, setSelectedGroup} = useGroupWizardStore();

    const [name, setName] = useState(group.name);
    const [description, setDescription] = useState(group.description);
    const [fields, setFields] = useState<NewGroup["fields"]>(group.fields.concat("pendingFields" in group ? group.pendingFields : []));
    const [iconUrl, setIconUrl] = useState(group.iconUrl);
    const [maxUsers, setMaxUsers] = useState(group.maxParticipants);
    const [existingMentorSelector, setExistingMentorSelector] = useState(false);

    const queryClient = useQueryClient();

    function modifyField(field: string, value: string): void {
        setFields(fields.map((f)=>{
            if (f.name === field) {
                return {
                    ...f,
                    value
                };
            }
            return f;
        }));
    }

    async function createOrUpdateGroup(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!iconUrl) {
            toast.error("Debes añadir un icono al grupo");
            return;
        }

        if (!name || !description || !iconUrl || !fields || !maxUsers) return;

        if (!("_id" in group)) {
            // Grupo no existe, crearlo
            const newGroup: NewGroup = {
                name,
                description,
                iconUrl,
                fields,
                maxParticipants: maxUsers
            };

            const response = await api.post<NewGroup, FullGroup>(`grouppages/${selectedGroupPage}/groups`, newGroup);

            if (!response) {
                toast.error("Error al crear el grupo");
                return;
            }

            // Invalidate data
            await queryClient.invalidateQueries(["groups", selectedGroupPage]);

            toast.success("Grupo creado correctamente");

            return;
        }

        // Grupo ya existe, actualizarlo
        const updatedGroup: NewGroup = {
            name,
            description,
            iconUrl,
            fields,
            maxParticipants: maxUsers
        };

        const response = await api.patch<NewGroup, FullGroup>(`groups/${group._id}`, updatedGroup);

        if (!response) {
            toast.error("Error al actualizar el grupo");
            return;
        }

        // Invalidate groups data
        await queryClient.invalidateQueries(["groups", selectedGroupPage]);

        toast.success("Grupo actualizado correctamente");

        setSelectedGroup(undefined);

        return;
    }

    return (
        <Fragment>
            <ExistingMentorSelector open={existingMentorSelector} setOpen={setExistingMentorSelector}/>
            <form className="border-solid border-gray-cool-400 border-2 rounded-xl flex gap-8 p-4" onSubmit={createOrUpdateGroup}>
                <div className="flex flex-col w-[45%] gap-4 flex-shrink-0">
                    <h3>Añade un icono y edita los campos de la tarjeta interactiva del grupo</h3>
                    <CardContainer>
                        <CardTop color={iframeData?.style.mainColor} name={name} iconUrl={iconUrl} setIconUrl={setIconUrl} setName={setName} subtitle="Número de participantes"/>

                        <CardBottom hasDescription description={description} fields={fields} modifyField={modifyField}
                            setDescription={setDescription}
                        />
                    </CardContainer>
                </div>
                <div className="flex-grow flex flex-col gap-4">
                    <h3>Ya solo falta invitar a los embajadores</h3>
                    <div className="flex flex-col gap-2">
                        <p className="font-semibold text-ug-sm">Máximo de participantes</p>
                        <UnigowInput type="number" required value={maxUsers} onChange={(e)=>setMaxUsers(parseInt(e.target.value))} placeholder="Número máximo de participantes"/>
                    </div>
                    {("_id" in group) && (
                        <div className="flex flex-col gap-2">
                            <p className="font-semibold text-ug-sm">Invita a los embajadores para que se unan al grupo (opcional)</p>
                            <p className="text-ug-sm text-gray-cool-400">Si quieres que haya embajadores que respondan a preguntas o lideren las conversaciones, haz click en uno de estos botones. Sino, pulsa en “Guardar el grupo”</p>
                            <div className="flex flex-wrap gap-2">
                                <MentorsInvite group={group._id}/>
                                <UnigowButton variant="basic" type="button" onClick={()=>setExistingMentorSelector(true)}>Invitar embajadores existentes</UnigowButton>
                            </div>
                        </div>
                    )}
                    <div>
                        <UnigowButton variant="secondary" type="submit">Guardar grupo</UnigowButton>
                    </div>
                </div>
            </form>
        </Fragment>
    );
}