import {Add, Delete} from "@mui/icons-material";
import {Accordion, AccordionDetails, AccordionSummary, Autocomplete, Checkbox, IconButton} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import {CardsPluginAPI} from "@unigow/apis/iframe";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import ExcludedDaysSelector from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/components/ExcludedDaysSelector";
import {EditCardProps} from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/EditCard";
import {CardType} from "@unigow/unigow-types";
import dayjs, {Dayjs} from "dayjs";
import React, {Children, FormEvent, useState} from "react";
import {toast} from "react-toastify";

export default function SlotsConfig({setStep}: EditCardProps): React.ReactElement {
    const {t} = useCustomLocale();
    const {selectedCard, modifyCard} = useCardWizardStore();

    const thisCard = selectedCard as CardType;

    const [timezone, setTimezone] = useState<string | null>(thisCard.reservationConfig?.timezone || dayjs.tz.guess());
    const [slotTime, setSlotTime] = useState<number>(thisCard.reservationConfig?.slotDuration || 60);
    const [slots, setSlots] = useState<Record<string, {start: string, end: string}[]>>(thisCard.reservationConfig?.slots || {
        "monday":[],
        "tuesday":[],
        "wednesday":[],
        "thursday":[],
        "friday":[],
        "saturday":[],
        "sunday":[]
    }
    );
    const [specialAttention, setSpecialAttention] = useState(thisCard.reservationConfig?.specialAttention || false);
    const [specialAttentionSlots, setSpecialAttentionSlots] = useState(thisCard.reservationConfig?.specialAttentionSlots || 2);
    const [excludedDays, setExcludedDays] = useState<Dayjs[]>(thisCard.reservationConfig?.excludedDates.map((d)=>dayjs(d)) || []);

    function getMinTimeOfDay(day: string, index: number): Dayjs {
        const daySlots = slots[day].filter((slot, i)=>slot.end !== "" && i < index);
        if (!daySlots.length) return dayjs().startOf("day");
        // Get the next hour to the last max time
        const lastSlot = daySlots[daySlots.length - 1];
        return dayjs(lastSlot.end, "HH:mm").add(1, "hour");
    }

    function getMaxTimeOfDay(day: string, index: number): Dayjs {
        const daySlots = slots[day].filter((slot, i)=>slot.start !== "" && i > index);
        if (!daySlots.length) return dayjs().endOf("day");
        // Get the next hour to the last max time
        const lastSlot = daySlots[daySlots.length - 1];
        return dayjs(lastSlot.start, "HH:mm").subtract(1, "hour");
    }

    // FUNCTIONS
    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!timezone) {
            toast.error("Debes seleccionar una zona horaria");
            return;
        }

        if (specialAttention && specialAttentionSlots > thisCard.maxParticipants) {
            toast.error(`El número de huecos que ocupa una reserva con necesidades especiales no puede ser mayor al número de huecos por reserva. Máximo actual: ${thisCard.maxParticipants}`);
            return;
        }

        const iframeResData: Partial<CardType["reservationConfig"]> = {
            slots,
            timezone,
            slotDuration:slotTime,
            specialAttention,
            specialAttentionSlots,
            // Preserve the timezone
            excludedDates:excludedDays.map((d)=>d.startOf("day").toDate())
        };

        if (!thisCard) {
            setStep("basic");
            return;
        }

        const response = await CardsPluginAPI.updateCard({cardId:thisCard._id.toString(), data:{reservationConfig:iframeResData}});

        if (response) {
            modifyCard("settings", response.settings);
            setStep("userparams");
        }
    }

    return (
        <div className="">
            <form className="flex flex-col gap-[15px] " onSubmit={handleSubmit}>
                <h2>Configura la disponibilidad</h2>
                <div className="flex flex-col gap-4">
                    <h3 className="text-[18px] font-semibold">Define la zona horaria de la tarjeta</h3>
                    <div>
                        <Autocomplete className="w-[30ch]" options={Intl.supportedValuesOf("timeZone")}
                            renderInput={(params)=>(
                                <UnigowInput required {...params} size="small" label="Zona Horaria"/>
                            )}
                            value={timezone}
                            onChange={(_, value)=>setTimezone(value)}
                        />
                    </div>
                    <div className="flex flex-col gap-2 w-[30ch]">
                        <h3>Tiempo de cada reserva (minutos)</h3>
                        <UnigowInput value={slotTime} type="number" required size="small" label="Tiempo de reserva"
                            onChange={(e)=>{
                                setSlotTime(parseInt(e.target.value));
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap gap-4">
                    {Children.toArray(Object.keys(slots).map((day)=>(
                        <div className="flex flex-col gap-2 w-full md:w-[calc(50%-3rem)] bg-gray-cool-100 border border-solid border-gray-cool-300 rounded-xl p-4">
                            <div className="flex justify-between items-center">
                                <h3 className="text-[18px] font-semibold">{t(day)}</h3>
                                <IconButton type="button" size="small" onClick={()=>{
                                    const newSlots = {...slots};
                                    newSlots[day].push({start:"", end:""});
                                    setSlots(newSlots);
                                }}
                                >
                                    <Add/>
                                </IconButton>
                            </div>
                            <div className="flex flex-col gap-2">
                                {Children.toArray(slots[day].map((slot, i)=>(
                                    <div className="flex gap-2">
                                        <div className="flex gap-2 items-center">
                                            <TimePicker className="bg-white" value={dayjs(slot.start, "HH:mm")} onChange={(date)=>{
                                                const newSlots = {...slots};
                                                // Set the start time as hh:mm
                                                newSlots[day][i].start = `${date?.hour().toString().padStart(2, "0")}:${date?.minute().toString().padStart(2, "0")}`;
                                                setSlots(newSlots);
                                            }} timeSteps={{minutes:slotTime}} maxTime={slot ? dayjs(slot.end, "HH:mm") : undefined}
                                            ampm={false} minTime={getMinTimeOfDay(day, i)}
                                            slotProps={{textField: {size: "small"}, inputAdornment:{position:"start"}}}
                                            />
                                            <p>-</p>
                                            <TimePicker className="bg-white" value={dayjs(slot.end, "HH:mm")} onChange={(date)=>{
                                                const newSlots = {...slots};
                                                newSlots[day][i].end = `${date?.hour().toString().padStart(2, "0")}:${date?.minute().toString().padStart(2, "0")}`;
                                                setSlots(newSlots);
                                            }} timeSteps={{minutes:slotTime}} minTime={slot ? dayjs(slot.start, "HH:mm") : getMinTimeOfDay(day, i)}
                                            ampm={false} maxTime={getMaxTimeOfDay(day, i)}
                                            slotProps={{textField: {size: "small"}, inputAdornment:{position:"start"}}}
                                            />
                                        </div>
                                        <IconButton type="button" size="small" color="error" onClick={()=>{
                                            const newSlots = {...slots};
                                            newSlots[day] = newSlots[day].filter((_, index)=>index !== i);
                                            setSlots(newSlots);
                                        }}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </div>
                                )))}
                            </div>
                            {slots[day].length > 0 && (
                                <div className="mt-2">
                                    <UnigowButton className="py-1" type="button" variant="basic" onClick={()=>{
                                        const currentSlots = slots[day];
                                        // Set all the days like the current day
                                        const newSlots = {...slots};
                                        Object.keys(newSlots).forEach((d)=>{
                                            newSlots[d] = currentSlots;
                                        });

                                        setSlots(newSlots);
                                    }}
                                    >
                                        Expander al resto de días
                                    </UnigowButton>
                                </div>
                            )}
                        </div>
                    )))}
                </div>
                <Accordion>
                    <AccordionSummary>Ajustes de necesidades especiales</AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-4 items-center">
                                <Checkbox checked={specialAttention} onChange={(e)=>{
                                    setSpecialAttention(e.target.checked);
                                }}
                                />
                                <p className="font-semibold text-ug-base">¿Permitir reservas de usuarios que requieran atención especial?</p>
                            </div>
                            {specialAttention && (
                                <div className="flex flex-col gap-4">
                                    <p className="font-semibold text-ug-base">Huecos que ocupa una reserva con necesidades especiales</p>
                                    <UnigowInput type="number" value={specialAttentionSlots} onChange={(e)=>{
                                        setSpecialAttentionSlots(parseInt(e.target.value));
                                    }}
                                    />
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div className="flex gap-2">
                    <ExcludedDaysSelector value={excludedDays} onChange={setExcludedDays}/>
                    <UnigowButton variant="secondary" type="submit">Siguiente Paso</UnigowButton>
                </div>
            </form>
        </div>
    );
}