import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import {DisplayGroup} from "@unigow/types/group";
import React, {HTMLAttributes} from "react";

interface GroupCardProps {
    groupInfo: DisplayGroup;
    ActionButton: React.ReactElement;
    color: string;
}

export default function GroupCard({groupInfo, ActionButton, color, ...divProps}: GroupCardProps & HTMLAttributes<HTMLDivElement>):
React.ReactElement {

    return (
        <CardContainer {...divProps}>
            <CardTop color={color} name={groupInfo.name} iconUrl={groupInfo.iconUrl} subtitle={`${groupInfo.participantNumber} participante${groupInfo.participantNumber === 1 ? "" : "s"}`}/>

            <CardBottom hasDescription description={groupInfo.description} fields={groupInfo.fields}/>

            {!!ActionButton && (
                <div className="px-4">
                    {ActionButton}
                </div>
            )}
        </CardContainer>
    );
}