import React, {useState, useEffect, useRef, Fragment} from "react";
import UnigowInput from "../../../components/UnigowInput/UnigowInput";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {useAuth} from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import {useIframe} from "../contexts/IframeContext";
import {Checkbox, IconButton, Radio} from "@mui/material";
import {ArrowBackIosNew, EmailOutlined, Google} from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import {useAuthStore} from "../../../stores/authStore";
import {useIframeStore} from "../../../stores/iframeStore";

function IframeRegister(): React.ReactElement {
    // STORES
    const {setCurrentPage} = useIframe();
    const {registerUser, signInWithGoogle, loading:authLoading} = useAuth();
    const {userData} = useAuthStore();
    const {iframeData, pendingAction} = useIframeStore();

    const [registerType, setRegisterType] = useState<"normal" | "minor" | undefined>(undefined);

    const [minorName, setMinorName] = useState("");
    const [minorSurnames, setMinorSurnames] = useState("");
    const [name, setName] = useState("");
    const [surnames, setSurnames] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [loading, setLoading] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [loginType, setLoginType] = useState<"email" | "google">("google");

    const privacyRef = useRef<HTMLInputElement>(null);

    function generateMinorEmail(): string {
        const emailParts = email.split("@");
        return `${emailParts[0]}+${minorName.toLowerCase()}@${emailParts[1]}`;
    }

    async function handleRegister(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!privacy) {
            toast.error("Debes aceptar las políticas de privacidad");
            privacyRef.current?.focus();
            return;
        }

        if (loading) return;

        setLoading(true);

        if (pass.length < 6) {
            toast.error("La contraseña debe tener al menos 6 caracteres");
            return;
        }

        if (pass !== confirmPass) {
            toast.error("Las contraseñas no coinciden");
            return;
        }

        if (!email || !pass) {
            toast.error("Debes rellenar el correo y la contraseña");
            setLoading(false);
            return;
        }

        if (registerType === "minor" && !minorName) {
            toast.error("Debes rellenar el nombre del menor");
            setLoading(false);
            return;
        }

        try {
            if (registerType === "minor") {
                // Add the minor name to the email as a decorator
                const emailParts = email.split("@");
                const minorEmail = `${emailParts[0]}+${minorName.toLowerCase()}@${emailParts[1]}`;

                await registerUser({name:minorName, surnames:minorSurnames, email:minorEmail, minor:true, parentName:name,
                    parentSurnames:surnames}, pass);
            } else {
                await registerUser({name:name, surnames:surnames, email}, pass);
            }
            setLoading(false);
        } catch {
            toast.error("Ese usuario ya está registrado");
            setLoading(false);
        }
    }

    // EFFECTS
    useEffect(()=>{
        if (authLoading) return;

        if (userData) {

            if (userData.additionalInfo) {
                const redirectTo = localStorage.getItem("redirectTo");
                localStorage.removeItem("redirectTo");
                setCurrentPage(redirectTo || "search");
                return;
            }

            setCurrentPage("form");
        }
    }, [userData, setCurrentPage, authLoading]);

    useEffect(()=>{
        if (!iframeData) return;

        if (iframeData.settings.ageRestriction === "adults") setRegisterType("normal");
        else if (iframeData.settings.ageRestriction === "minors") {
            setRegisterType("minor");
            setLoginType("email");
        }
    }, [iframeData]);

    async function handleGoogleLogin(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!privacy) {
            toast.error("Debes aceptar las políticas de privacidad");
            privacyRef.current?.focus();
            return;
        }
        try {
            await signInWithGoogle();
        } catch (err) {
            Sentry.captureException(err);
            toast.error("Se ha producido un error");
        }
    }

    function renderPrivacyText(): React.ReactElement {
        if (!!pendingAction?.privacy && !!iframeData && iframeData.clientInfo.partner) {
            return (
                <label htmlFor="privacy" className="text-ug-sm select-none">
                    Entiendo que Unigow almacenará y procesará mis datos personales de acuerdo con su <a rel="noopener noreferrer" target="_blank" className="underline font-medium text-black" href="/privacy">Política de Privacidad</a>.
                </label>
            );
        }
        return (
            <label htmlFor="privacy" className="text-ug-sm select-none">
                Entiendo que mis datos personales pueden ser procesados por {iframeData?.clientInfo.name} de acuerdo con su
                <a rel="noopener noreferrer" target="_blank" className="underline font-medium text-black" href={iframeData?.clientInfo.privacy as string}> Política de Privacidad</a> y que Unigow almacenará y procesará mis datos personales de acuerdo con su <a rel="noopener noreferrer" target="_blank" className="underline font-medium text-black" href="/privacy">Política de Privacidad</a>.
            </label>
        );
    }

    if (!registerType) {
        return (
            <div className="w-10/12 mx-auto flex flex-col gap-4">
                <div className="flex gap-8 items-center">
                    <IconButton onClick={()=>setCurrentPage("search")}>
                        <ArrowBackIosNew/>
                    </IconButton>
                    <div className="flex flex-col gap-4">
                        <h3>¿Eres mayor de 14 años?</h3>
                        <p>Esta pregunta hace referencia a la persona inscrita</p>
                    </div>
                </div>
                <div className="flex w-full gap-4">
                    <UnigowButton onClick={()=>{
                        setRegisterType("minor");
                        setLoginType("email");
                    }} type="button" variant="basic" className="flex-1"
                    >No, soy menor de 14 años
                    </UnigowButton>
                    <UnigowButton onClick={()=>setRegisterType("normal")} type="button" variant="primary" className="flex-1">Sí, soy mayor de 14 años</UnigowButton>
                </div>
            </div>
        );
    }

    function getFieldName(field: string): string {
        if (registerType === "minor") return `${field} del representante`;

        return field;
    }

    return (
        <div className="w-10/12 mx-auto flex flex-col gap-4">
            <div className="flex gap-8 items-center">
                <IconButton onClick={()=>setRegisterType(undefined)}>
                    <ArrowBackIosNew/>
                </IconButton>
                <div className="flex flex-col gap-4">
                    <h3>Regístrate para descubrir experiencias reales</h3>
                    {registerType === "minor" && (
                        <p><span className="font-semibold">IMPORTANTE:</span> Este registro debe ser rellenado por uno de los representantes legales del menor</p>
                    )}
                </div>
            </div>
            {registerType === "normal" && (
                <div className="flex w-full flex-col gap-2">
                    {/* Grid con radio button a la izquierda y texto centrado a la derecha */}
                    <UnigowButton onClick={()=>setLoginType("google")} type="button"
                        variant="basic" className="grid grid-cols-[auto,1fr] gap-4 items-center"
                    >
                        <Radio className="p-0" checked={loginType === "google"} color="primary"  onChange={()=>setLoginType("google")}/>
                        <div className="flex items-center justify-center gap-2">
                            <Google/> <p>Registrarse con Google</p>
                        </div>

                    </UnigowButton>
                    <UnigowButton onClick={()=>setLoginType("email")} type="button"
                        variant="basic" className="grid grid-cols-[auto,1fr] gap-4 items-center"
                    >
                        <Radio className="p-0" checked={loginType === "email"} onChange={()=>setLoginType("email")}/>
                        <div className="flex items-center justify-center gap-2">
                            <EmailOutlined/> <p>Registrarse con Email</p>
                        </div>
                    </UnigowButton>
                </div>
            )}
            {loginType === "email" ? (
                <form className="flex flex-col gap-4" onSubmit={handleRegister}>
                    {registerType === "minor" && (
                        <Fragment>
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-lg font-semibold">Nombre del menor</p>
                                <UnigowInput required type="text" className="text-ug-base font-light text-gray-cool-400" autoComplete="name" value={minorName} onChange={(e)=>setMinorName(e.target.value)}/>
                            </div>
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-lg font-semibold">Apellidos del menor</p>
                                <UnigowInput required type="text" className="text-ug-base font-light text-gray-cool-400" autoComplete="last-name" value={minorSurnames} onChange={(e)=>setMinorSurnames(e.target.value)}/>
                            </div>
                        </Fragment>
                    )}
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-lg font-semibold">{getFieldName("Nombre")}</p>
                        <UnigowInput required type="text" className="text-ug-base font-light text-gray-cool-400" autoComplete="name" value={name} onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-lg font-semibold">{getFieldName("Apellidos")}</p>
                        <UnigowInput required type="text" className="text-ug-base font-light text-gray-cool-400" autoComplete="last-name" value={surnames} onChange={(e)=>setSurnames(e.target.value)}/>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-lg font-semibold">{getFieldName("Email")}</p>
                        <UnigowInput required type="email" className="text-ug-base font-light text-gray-cool-400" autoComplete="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    {registerType === "minor" && !!minorName && !!email && email.split("@").length > 1 && (
                        <div className="flex flex-col gap-[10px]">
                            <p className="text-ug-lg font-semibold">Email para entrar en tu cuenta</p>
                            <UnigowInput disabled aria-readonly type="email" className="text-ug-base font-light text-gray-cool-400" autoComplete="email" value={generateMinorEmail()}/>
                            <small><span className="font-semibold">ATENCIÓN</span>: Con este correo crearemos tu cuenta en el ayuntamiento para futuras inscripciones de {minorName}.</small>
                        </div>
                    )}
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-lg font-semibold">Contraseña</p>
                        <UnigowInput required type="password" className="text-ug-base font-light text-gray-cool-400" autoComplete="new-password" value={pass} onChange={(e)=>setPass(e.target.value)}/>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-lg font-semibold">Repetir contraseña</p>
                        <UnigowInput required type="password" className="text-ug-base font-light text-gray-cool-400" autoComplete="new-password" value={confirmPass} onChange={(e)=>setConfirmPass(e.target.value)}/>
                    </div>
                    <div className="flex items-center gap-4">
                        <Checkbox
                            inputRef={privacyRef}
                            value={privacy}
                            onChange={(e)=>setPrivacy(e.target.checked)}
                            id="privacy"
                            required
                            className="p-0"
                        />
                        {renderPrivacyText()}
                    </div>
                    <UnigowButton type="submit" className="flex items-center justify-center gap-2 py-1" variant="primary"><EmailOutlined/> Continuar con Email</UnigowButton>
                </form>
            ) : (
                <form className="flex flex-col gap-4" onSubmit={handleGoogleLogin}>
                    <div className="flex items-center gap-4">
                        <Checkbox
                            inputRef={privacyRef}
                            value={privacy}
                            onChange={(e)=>setPrivacy(e.target.checked)}
                            id="privacy"
                            required
                            className="p-0"
                        />
                        {renderPrivacyText()}
                    </div>
                    <UnigowButton type="submit" className="flex items-center justify-center gap-2 py-1" variant="primary"><Google/> Continuar con Google</UnigowButton>
                </form>
            )}
            <div className="">
                <p className="text-ug-sm text-primary-500 underline cursor-pointer w-fit" onClick={()=>setCurrentPage("login")}>Ya tengo cuenta</p>
            </div>
        </div>
    );
}

export default IframeRegister;