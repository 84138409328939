import {Add} from "@mui/icons-material";
import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import EditCard from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/EditCard";
import {CardType} from "@unigow/unigow-types";
import React, {Children, useEffect} from "react";
import {useParams} from "react-router-dom";

function newCardTemplate(): Pick<CardType, "name"> {
    return {
        name:""
    };
}

export default function Cards(): React.ReactElement {
    const {cardId} = useParams();
    const {iframeData} = useIframeWizardStore();
    const {cardPageCards, cardPageData, selectedCard, selectCard, setConfigPage} = useCardWizardStore();

    useEffect(()=>{
        if (!cardPageCards || !cardPageData) return;

        if (cardId) {
            const card = cardPageCards.find((c)=>c._id.toString() === cardId);
            if (card) {
                selectCard(card);
            }
        }

        if (cardPageCards.length === 0) {
            selectCard(newCardTemplate());
        }
    }, [cardPageCards, cardPageData, selectCard, cardId, setConfigPage]);

    if (!cardPageCards || !cardPageData) return (<div>Loading...</div>);

    return (
        <div className="w-full flex flex-col gap-4">
            <div className="">
                <h2>Crea tantas tarjetas como quieras</h2>
            </div>
            <div className="">
                {selectedCard ? (
                    <EditCard/>
                ) : (
                    <ul className="flex flex-wrap gap-4">
                        {Children.toArray((cardPageCards).map((card)=>(
                            <li className="w-full lg:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] relative">
                                <CardContainer>
                                    <CardTop color={iframeData?.style.mainColor} name={card.name}
                                        iconUrl={card.iconUrl}
                                    />

                                    <CardBottom fields={card.fields}/>

                                    <div className="px-4 flex flex-col gap-2">
                                        <UnigowButton buttonType="button" ref={(node)=>{
                                            if (node) {
                                                node.style.setProperty("background-color", iframeData?.style.mainColor || "", "important");
                                            }
                                        }}
                                        onClick={()=>selectCard(card)}
                                        className={"w-full"} variant="primary"
                                        >Editar tarjeta
                                        </UnigowButton>
                                        <UnigowButton buttonType="anchor" href=""
                                            target="popup"  onClick={()=>{
                                                window.open(`/views/cards/${card._id}/participants`, "_blank", "width=600,height=600,scrollbars=no,resizable=no");
                                            }}
                                            className={"py-1"} variant="basic"
                                        >
                                            Ver participantes
                                        </UnigowButton>
                                    </div>
                                </CardContainer>
                            </li>
                        ))
                        )}
                    </ul>
                )}
            </div>
            {cardPageData && !selectedCard && (
                <div className="flex gap-4">
                    <UnigowButton variant="basic" className="flex items-center gap-2" onClick={()=>{
                        selectCard(newCardTemplate());
                    }}
                    >Crear otra tarjeta <Add className="w-3 h-3 border border-solid rounded-full"/>
                    </UnigowButton>
                    <UnigowButton variant="secondary" onClick={()=>{
                        setConfigPage("config");
                    }}
                    >Terminar la activación de tarjetas
                    </UnigowButton>
                </div>
            )}
        </div>
    );
}