import React from "react";
import {ReactComponent as Unicoin} from "@unigow/assets/unicoin.svg";
import {useQuery} from "react-query";
import {api} from "../../../../../apis/requests";
import {useMediaQuery} from "react-responsive";
import {useAuthStore} from "../../../../../stores/authStore";

export default function UnicoinBalance(): React.ReactElement {
    const {userData} = useAuthStore();

    const isMobile = useMediaQuery({maxWidth:"768px"});

    const {data = 0} = useQuery(`${userData?._id}-balance`, async()=>{
        if (!userData || !userData._id) return;

        const res = await api.get<{netAmount: number}>("movements");
        return res.netAmount;
    });

    return (
        <div className="flex gap-1 items-center text-[18px] leading-[133%]">
            <p>{!isMobile ? "Has acumulado " : ""}<span className="font-semibold">{data}</span></p>
            <Unicoin width={30} height={30}/>
        </div>
    );
}