export class HttpError extends Error {
    public readonly status: number;

    public constructor(message: string, status: number) {
        super(message);
        this.status = status;
    }
}

export interface UnigowError {
    timestamp: string;
    errorCode: string;
    description: string;
    details?: string;
}

export class UnigowHttpError extends HttpError {
    public readonly timestamp: string;
    public readonly errorCode: string;
    public readonly description: string;
    public readonly details?: string;

    public constructor(error: UnigowError) {
        super(error.description, 500);
        this.timestamp = error.timestamp;
        this.errorCode = error.errorCode;
        this.description = error.description;
    }
}