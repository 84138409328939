import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import {useMediaQuery} from "react-responsive";
import {twMerge} from "tailwind-merge";
import UnicoinBalance from "./pages/Awards/components/UnicoinBalance";
import {useDashboardStore} from "../../stores/dashboard/dashboardStore";
import {useQuery} from "react-query";
import {api} from "../../apis/requests";
import useUnigowStore from "../../stores/unigowStore";
import {Autocomplete, IconButton, Skeleton} from "@mui/material";
import {ReactComponent as SupportIcon} from "@unigow/assets/support.svg";
import {getLocalStorage} from "../../helpers/localstorage";
import {Notification} from "../../types/notifications";
import NotificationWindow from "./components/NotificationWindow/NotificationWindow";
import Support from "./components/Support/Support";
import {useAuth} from "../../contexts/AuthContext";
import {Loading} from "../Loading/Loading";
import {orientatorAnsweredQuestions} from "../../helpers/chat";
import {useAuthStore} from "../../stores/authStore";
import {CSSTransition} from "react-transition-group";
import UnigowInput from "../../components/UnigowInput/UnigowInput";
import {Menu} from "@mui/icons-material";

import "./style.css";
import {ClientWithPermissions} from "@unigow/types/clients";
import {ChatsPluginType} from "@unigow/unigow-types";
import {UnigowError} from "@unigow/types/error";

function Dashboard(): React.ReactElement {
    // MISCELLANEOUS
    const isMobile = useMediaQuery({maxWidth:"768px"});
    const navigate = useNavigate();

    // STORES
    // Orientator Store
    const {setAvailableClients, selectedClient, selectClient,
        setClientInfo, clientInfo, setNotifications, clientLoading,
        setClientLoading, setAvailableChatPlugins, selectPlugin, setPluginInfo, availableClients, availableChatPlugins} = useDashboardStore();

    // Manager Store
    const {openSupport} = useUnigowStore();
    const {userData} = useAuthStore();
    const {logoutUser} = useAuth();

    // STATES
    const [folded, setFolded] = useState(true);
    const [showSidebar, setShowSidebar] = useState(isMobile ? false : true);

    // QUERIES
    // Busca información sobre los clientes de los que el usuario tiene permisos
    useQuery("clients", async()=>{
        return api.get<{_id: string, name: string, partner: boolean}[]>("clients");
    }, {onSuccess:async(data)=>{
        // Si el usuario no tiene ningún tipo de permisos para ningún cliente, se le redirige al login
        if (data.length === 0) {
            await logoutUser({redirect:"/login", error:true});
            return;
        }

        setAvailableClients(data);

        if (!selectedClient && data.length > 0) {
            // Se busca si el usuario estaba en algún cliente antes de salirse
            const previousClient = getLocalStorage("lastClient");

            if (previousClient && !!data.find((client)=>client._id === previousClient)) {
                return selectClient(previousClient);
            }

            // Si no había seleccionado ningún cliente, se selecciona el primero de la lista
            selectClient(data[0]._id);
        }
    }, refetchOnWindowFocus:true});

    // Busca información sobre el cliente seleccionado
    const {isLoading, isSuccess} = useQuery(["client", selectedClient], async()=>{
        if (window.location.pathname.includes("iframes")) {
            navigate("/iframes");
        }

        setClientLoading(true);

        return api.get<ClientWithPermissions>(`clients/${selectedClient}`);
    }, {enabled:!!selectedClient,
        onSuccess:(data)=>{
            if (!data) return;

            setClientInfo(data);
            setClientLoading(false);

            if (window.location.pathname !== "/") return;

            if (data.permissions?.manager) {
                return navigate("/iframes");
            }

            return navigate("/chats");
        }});

    // Busca las notificaciones del usuario para el cliente seleccionado
    useQuery(["notifications", selectedClient], async()=>{
        return api.get<Notification[]>(`notifications/client/${selectedClient}`);
    }, {onSuccess:(data)=>{
        // Get the try counter from cookies
        const tryCounter = JSON.parse(getLocalStorage("tryCounter") || "{}") as Record<string, number>;

        // if the try counter exists and is greater than 3, remove the notification from the array
        setNotifications(data.filter((notification)=>!(tryCounter[notification._id] > 2)));
    }, enabled:!!clientInfo && clientInfo.permissions?.orientator});

    // Busca información sobre los iframes de los que el usuario es embajador
    useQuery("userIframes", async()=>{
        return api.get<ChatsPluginType[]>("orientators/mychatsplugins");
    }, {
        onSuccess:(data)=>{
            setAvailableChatPlugins(data);

            if (data.length > 0) {
                selectPlugin(data[0]._id.toString());
                setPluginInfo(data[0]);
            }
        }
    });

    useEffect(()=>{
        if (!availableChatPlugins || !userData || !clientInfo?.permissions.orientator) return;

        async function checkPluginReto(reto: string): Promise<void> {
            try {
                await api.get(`retosanswers/${reto}`);
            } catch (e) {
                const err = e as UnigowError;

                if (err.errorCode === "reto/not-found") {
                    return;
                }

                // User has no answer
                navigate(`/fillreto/${reto}`);
            }

        }

        // Get list of allparams without duplicated
        const allParams = availableChatPlugins.map((plugin)=>plugin.settings.mentorParams).flat()
            .filter((param, index, self)=>self.findIndex((p)=>p === param) === index);

        const mentorAnsweredQuestions = orientatorAnsweredQuestions(allParams, userData?.additionalInfo);

        if ((!mentorAnsweredQuestions || !userData?.phone || userData.phone === "")) {
            return navigate("/completeregister");
        }

        const allRetos = availableChatPlugins.map((plugin)=>plugin.settings.reto || null).filter((reto)=>!!reto);

        allRetos.forEach((reto)=>checkPluginReto(reto.toString()));

        if (!userData.profilePic || userData.profilePic === "") {
            return navigate("/account");
        }

    }, [availableChatPlugins, userData, navigate, clientInfo]);

    useEffect(()=>{
        if (folded) {
            document.documentElement.style.setProperty("--sidebar-width", "5rem");
            return;
        }
        document.documentElement.style.setProperty("--sidebar-width", "19rem");
    }, [folded]);

    if (!selectedClient || !clientInfo) return <Loading/>;

    const currentPage = window.location.pathname.split("/");

    if (currentPage.length < 2) {
        if (clientInfo.permissions.manager) {
            navigate("/iframes");
        } else {
            navigate("/chats");
        }
    }

    return (
        <div className="flex bg-gray-cool-100 overflow-hidden [h-100svh] relative">
            <CSSTransition classNames="leftbar" timeout={300} in={showSidebar}>
                <Sidebar folded={folded} setFolded={setFolded} loadingPermissions={isLoading || !isSuccess}/>
            </CSSTransition>
            <NotificationWindow/>
            <Support/>
            <CSSTransition classNames="maincontent" timeout={300} in={showSidebar}>
                <div className="inline-table lg:flex w-full" style={{paddingLeft:!isMobile ? "var(--sidebar-width)" : 0}}>
                    <div className={twMerge("h-[100svh] w-full")}>
                        <div className="py-3 h-14 bg-white border-b border-x-0 border-t-0 border-solid border-b-gray-cool-300 flex justify-end">
                            <IconButton className="ml-2" onClick={()=>{
                                setShowSidebar((prev)=>!prev);
                            }}
                            >
                                <Menu className="dark:text-white p-1"/>
                            </IconButton>
                            <div className="lg:pr-48 flex items-center lg:gap-9 justify-evenly w-full lg:justify-end">
                                {availableClients.length > 1 && (
                                    <div className="flex gap-2 items-center">
                                        <Autocomplete
                                            size="small"
                                            className="lg:w-[30ch]"
                                            options={availableClients}
                                            getOptionLabel={(option)=>option.name}
                                            renderInput={(params)=>(
                                                <UnigowInput {...params} placeholder="Elegir cliente"/>
                                            )}
                                            value={availableClients.find((client)=>client._id === selectedClient) || null}
                                            onChange={(_, value)=>{
                                                if (!value) return;

                                                selectClient(value._id);
                                            }}
                                            groupBy={(option)=>typeof option === "object" ? "Clientes" : ""}
                                            autoHighlight
                                            clearOnEscape
                                        />
                                    </div>
                                )}
                                {currentPage.includes("awards") && clientInfo && (
                                    <UnicoinBalance/>
                                )}
                                <IconButton className="text-primary-500" onClick={openSupport}>
                                    <SupportIcon/>
                                </IconButton>
                                {clientLoading ? (
                                    <Skeleton variant="rectangular" width={100} height={40}/>
                                ) : (
                                    <div className="flex items-center">
                                        <img className="max-w-full h-10" src={clientInfo?.logo} alt="" />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="overflow-y-auto h-[calc(100svh-5rem)] overflow-x-hidden" id="dashboard-container">
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default Dashboard;