/** Helper for creating a cookie */
export function setCookie(name: string, value: string | boolean, days: number): void {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `Expires=${date.toUTCString()};`;
    }
    document.cookie = `${name}=${value}; Path=/; ${expires}; SameSite=None; Secure;Partitioned;Key=aux;`;
}

/** Helper for removing a cookie */
export function deleteCookie(name: string): void {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure;Partitioned;Key=aux;`;
}

/** Helper for reading a cookie */
function keyValueStringToObj(str: string): Record<string, string> {
    const arr = str.split(";");
    const obj: Record<string, string>  = {};
    arr.forEach((value) => {
        const equalSignPos = value.indexOf("=");
        obj[value.slice(0, equalSignPos).trim()] = value.slice(equalSignPos + 1).trim();
    });
    return obj;
}
export function getCookie(cookieName: string): string | undefined {
    return keyValueStringToObj(document.cookie)[cookieName];
}

/** Helper for setting the local storage */
export function setLocalStorage(name: string, value: string): void {
    try {
        localStorage.setItem(name, value);
    } catch (e) {
        setCookie(`aux-${name}`, value, 1);
    }
}

/** Helper for getting a local storage variable */
export function getLocalStorage(name: string): string | null {
    try {
        const data = localStorage.getItem(name);
        return data;
    } catch (e) {
        getCookie(`aux-${name}`);
    }
    return null;
}

export function getLocalStorageObj<T>(name: string): T | null {
    try {
        const data = localStorage.getItem(name);
        if (data) {
            return JSON.parse(data) as T;
        }
    } catch (e) {
        getCookie(`aux-${name}`);
    }
    return null;
}

/** Helper for deleting a local storage variable */
export function removeLocalStorage(name: string): void {
    try {
        localStorage.removeItem(name);
    } catch (e) {
        deleteCookie(`aux-${name}`);
    }
}

/** Remove all firebase indexed DBs */
export async function deleteIndexedDB(): Promise<void> {
    const dbs = await window.indexedDB.databases();
    dbs.forEach((db)=>{
        window.indexedDB.deleteDatabase(db.name || "");
    });
}