import {IframeType} from "@unigow/unigow-types";
import {create} from "zustand";

type IframeWizardStore = {
    // Lista de iframes del cliente
    clientIframes: IframeType[] | undefined;

    // Función para modificar la lista de iframes
    setClientIframes: (iframes: IframeType[])=> void;

    // Función para añadir un iframe a la lista de iframes
    addClientIframe: (iframe: IframeType)=> void;

    // Función para modificar un iframe de la lista de iframes
    setClientIframe: (iframe: IframeType)=> void;

    // Función para renombrar un iframe
    renameClientIframe: (iframeId: IframeType["_id"], newName: string)=> void;

    // Id del iframe seleccionado
    selectedIframe: string | undefined;

    // Función para seleccionar un iframe
    selectIframe: (iframeId: string)=> void;

    // Información del iframe seleccionado
    iframeData: IframeType | undefined;

    // Función para modificar la información del iframe seleccionado
    setIframeData: (iframe: IframeType | undefined)=> void;

    // Función para modificar un parámetro de la información del iframe seleccionado
    modifyIframeData: <K extends keyof IframeType>(property: K, value: IframeType[K])=> void;
};

export const useIframeWizardStore = create<IframeWizardStore>((set, get) => ({
    clientIframes: undefined,
    setClientIframes:(iframes)=>{
        set({clientIframes:iframes});
    },
    addClientIframe:(iframe)=>{
        const {clientIframes} = get();
        set({clientIframes:[...(clientIframes || []), iframe]});
    },
    setClientIframe:(iframe)=>{
        const {clientIframes} = get();
        if (!clientIframes) return;

        const updatedClientIframes = clientIframes.map((oldIframe)=>{
            if (oldIframe._id === iframe._id) {
                return iframe;
            }

            return oldIframe;
        });

        set({clientIframes:updatedClientIframes});
    },
    renameClientIframe:(iframeId, newName)=>{
        const {clientIframes} = get();
        if (!clientIframes) return;

        const updatedClientIframes = clientIframes.map((iframe)=>{
            if (iframe._id === iframeId) {
                return {
                    ...iframe,
                    alias:newName
                };
            }

            return iframe;
        });

        set({clientIframes:updatedClientIframes});
    },
    selectedIframe:undefined,
    selectIframe:(iframeId)=>{
        if (iframeId === "temp") {
            set({iframeData:undefined});
        }
        set({selectedIframe:iframeId});
    },
    iframeData:undefined,
    setIframeData:(iframe)=>{
        set({iframeData:iframe});
    },
    modifyIframeData:(property, value)=>{
        const {iframeData} = get();
        if (!iframeData) return;

        const updatedIframeData = {
            ...iframeData,
            [property]: value
        };

        set({iframeData:updatedIframeData});
    }
}));