import {DeleteOutlineOutlined} from "@mui/icons-material";
import {IconButton, MenuItem} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {api} from "@unigow/apis/requests";
import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import UnigowSelect from "@unigow/components/UnigowSelect/UnigowSelect";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import {EditCardProps} from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/EditCard";
import {RecursivePartial} from "@unigow/types/custom";
import {CardType} from "@unigow/unigow-types";
import dayjs, {Dayjs} from "dayjs";
import React, {Children, useState} from "react";
import {useQueryClient} from "react-query";
import {toast} from "react-toastify";

export default function CardsStep1({setStep}: EditCardProps): React.ReactElement {
    const queryClient = useQueryClient();

    const {iframeData} = useIframeWizardStore();
    const {selectedCardPage, selectedCard, modifyCard, selectCard} = useCardWizardStore();

    const [name, setName] = useState<string>(selectedCard?.name || "");
    const [fields, setFields] = useState<CardType["fields"]>(((selectedCard && "fields" in selectedCard) ? selectedCard?.fields : [{
        name:"",
        value:"",
        type:"shorttext"
    }]));
    const [iconUrl, setIconUrl] = useState(((selectedCard && "iconUrl" in selectedCard) ? selectedCard?.iconUrl : ""));
    const [type, setType] = useState<"default" | "reservations" | "activity">((selectedCard && "type" in selectedCard) ? selectedCard.type : "default");
    const [startDate, setStartDate] = useState<Dayjs | null>((selectedCard && "startDate" in selectedCard) ? dayjs(selectedCard.startDate) : null);
    const [reservationsDate, setReservationsDate] = useState<Dayjs | null>((selectedCard && "startReservationDate" in selectedCard) ? dayjs(selectedCard.startReservationDate) : null);
    const [maxParticipants, setMaxParticipants] = useState((selectedCard && "maxParticipants" in selectedCard) ? selectedCard.maxParticipants : 50);
    const [minDaysInAdvance, setMinDaysInAdvance] = useState((selectedCard && "reservationConfig" in selectedCard) ? selectedCard.reservationConfig?.minDaysInAdvance : 7);

    function modifyField(field: string, value: string): void {
        setFields(fields.map((f)=>{
            if (f.name === field) {
                return {
                    ...f,
                    value
                };
            }
            return f;
        }));
    }

    async function createOrUpdateCard(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!iconUrl) {
            toast.error("Debes añadir un icono a la tarjeta");
            return;
        }

        if (!name || !iconUrl || !fields) return;

        if (maxParticipants < 1) {
            toast.error("El número de participantes debe ser mayor a 0");
            return;
        }

        if (!selectedCard || !("_id" in selectedCard)) {
            // Tarjeta no existe, crearlo
            const newCard: Pick<CardType, "name" | "iconUrl" | "fields" | "type" | "maxParticipants" | "startDate"> = {
                name,
                iconUrl,
                fields,
                type,
                maxParticipants,
                startDate: startDate ? startDate.toDate() : undefined
            };

            const response = await api.post<typeof newCard, CardType>(`plugincards/${selectedCardPage}/cards`, newCard);

            if (!response) {
                toast.error("Error al crear la tarjeta");
                return;
            }

            // Invalidate data
            await queryClient.invalidateQueries(["cards", selectedCardPage]);

            selectCard({...response, participantsInfo:[]});

            toast.success("Tarjeta creada correctamente");

            setStep(type === "default" ? "userparams" : "slots");

            return;
        }

        // Tarjeta ya existe, actualizarlo
        const updatedCard: RecursivePartial<CardType> = {
            name,
            iconUrl,
            type,
            fields,
            maxParticipants,
            startReservationDate: startDate?.toDate()
        };

        if (type === "activity") {
            updatedCard.startDate = startDate?.toDate();
        } else if (type === "reservations") {
            updatedCard.reservationConfig = {
                minDaysInAdvance: minDaysInAdvance || 7
            };
            updatedCard.startDate = undefined;
        }

        const response = await api.patch<RecursivePartial<CardType>, CardType>(`cards/${selectedCard._id}`, updatedCard);

        if (!response) {
            toast.error("Error al actualizar la tarjeta");
            return;
        }

        // Invalidate cards data
        await queryClient.invalidateQueries(["cards", selectedCardPage]);

        toast.success("Tarjeta actualizada correctamente");

        modifyCard("name", response.name);
        modifyCard("iconUrl", response.iconUrl);
        modifyCard("fields", response.fields);
        modifyCard("type", response.type);
        modifyCard("maxParticipants", response.maxParticipants);
        modifyCard("startDate", response.startDate);

        setStep(type === "reservations" ? "slots" : "userparams");

        return;
    }

    return (
        <form className="flex gap-4 p-4 flex-col" onSubmit={createOrUpdateCard}>
            <div className="flex flex-col lg:flex-row gap-4">
                <div className="flex flex-col gap-4">
                    <h3 className="text-[18px] font-semibold">Crea la estructura</h3>
                    <div className="flex flex-col gap-[10px]">
                        <p className="font-semibold text-ug-base">Define el tipo de tarjeta</p>
                        <div>
                            <UnigowSelect className="w-[20ch]" value={type} onChange={(e)=>{
                                setType(e.target.value as typeof type);
                            }}
                            >
                                <MenuItem value="default">Básica</MenuItem>
                                <MenuItem value="reservations">Reservas</MenuItem>
                                <MenuItem value="activity">Actividad</MenuItem>
                            </UnigowSelect>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                        <p className="font-semibold text-ug-base">Máximo de participantes {type === "reservations" && "por cada reserva"}</p>
                        <UnigowInput type="number" value={maxParticipants} onChange={(e)=>{
                            setMaxParticipants(parseInt(e.target.value));
                        }} InputProps={{inputProps:{min:1}}}
                        />
                    </div>

                    {type === "activity" ? (
                        <div className="flex flex-col gap-[10px]">
                            <p className="font-semibold text-ug-base">Fecha de Inicio</p>

                            <DateTimePicker value={startDate} onChange={(v)=>{
                                setStartDate(v);
                            }} format="DD/MM/YYYY" disablePast inputRef={(input)=>{
                                if (!input) return;

                                input.style.padding = "8.5px 14px";
                            }}
                            />
                        </div>
                    ) : type === "reservations" && (
                        <div className="flex flex-col gap-[10px]">
                            <p className="font-semibold text-ug-base">¿Con cuántos días de antelación se pueden hacer reservas?</p>
                            <UnigowInput type="number" value={minDaysInAdvance} onChange={(e)=>{
                                setMinDaysInAdvance(parseInt(e.target.value));
                            }} InputProps={{inputProps:{min:0}}}
                            />
                        </div>
                    )}

                    <div className="flex flex-col gap-[10px]">

                        <p className="font-semibold text-ug-base">{type === "activity" ? "Comienzo del periodo para apuntarse" : "Comienzo del periodo para reservas"}</p>

                        <DateTimePicker value={reservationsDate} onChange={(v)=>{
                            setReservationsDate(v);
                        }} format="DD/MM/YYYY [a partir de las] HH:mmA" disablePast inputRef={(input)=>{
                            if (!input) return;

                            input.style.padding = "8.5px 14px";
                        }}
                        />
                    </div>

                    <div className="flex flex-col gap-2 ">
                        <p className="font-semibold text-ug-base">¿Qué campos quieres incluir en las tarjetas? </p>
                        <p className="font-light text-ug-base">Además de la foto y los campos obligatorios, puedes crear otros campos.</p>
                        <p className="text-ug-base font-semibold">Nombre *</p>
                        {Children.toArray(fields.map((field)=>(
                            <div className="flex gap-4 items-center">
                                <UnigowInput placeholder="Nombre del campo" type="text" value={field.name} onChange={(e)=>{
                                    setFields(fields.map((f)=>{
                                        if (f.name === field.name) {
                                            return {
                                                ...f,
                                                name:e.target.value
                                            };
                                        }
                                        return f;
                                    }));
                                }}
                                />
                                <UnigowSelect value={field.type} onChange={(e)=>{
                                    setFields(fields.map((f)=>{
                                        if (f.name === field.name) {
                                            return {
                                                ...f,
                                                type:e.target.value as "shorttext" | "longtext"
                                            };
                                        }
                                        return f;
                                    }));
                                }}
                                >
                                    <MenuItem value="shorttext">Texto corto</MenuItem>
                                    <MenuItem value="longtext">Texto largo</MenuItem>
                                </UnigowSelect>
                                <IconButton onClick={()=>{
                                    setFields(fields.filter((f)=>f.name !== field.name));
                                }}
                                >
                                    <DeleteOutlineOutlined/>
                                </IconButton>
                            </div>
                        )))}
                        <div className="flexgap-4">
                            <UnigowButton variant="basic" type="button"
                                onClick={()=>{
                                    // Check if there are empty fields
                                    if (fields.some((f)=>!f.name)) {
                                        toast.error("Tienes campos vacíos");
                                        return;
                                    }

                                    setFields([...fields, {
                                        name:"",
                                        value:"",
                                        type:"shorttext"
                                    }]);
                                }}
                            >Añadir más campos
                            </UnigowButton>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-5/12">
                    <div className="flex flex-col gap-4 flex-shrink-0">
                        <h3>Añade una foto y edita los campos</h3>
                        <CardContainer>
                            <CardTop color={iframeData?.style.mainColor} name={name} iconUrl={iconUrl} setIconUrl={setIconUrl}
                                setName={setName}
                            />

                            <CardBottom fields={fields} modifyField={modifyField} timetable={(selectedCard && ("type" in selectedCard) && selectedCard.type === "reservations") ? selectedCard.reservationConfig?.slots : undefined}/>
                        </CardContainer>
                    </div>
                </div>
            </div>
            <div className="flex gap-2">
                <UnigowButton variant="basic" type="button" onClick={()=>{
                    selectCard(undefined);
                    setStep("basic");
                }}
                >Cancelar
                </UnigowButton>
                <UnigowButton variant="secondary" type="submit">{(!selectedCard || !("_id" in selectedCard)) ? "Crear Tarjeta" : "Siguiente Paso"}</UnigowButton>
            </div>
        </form>
    );
}