import {api} from "@unigow/apis/requests";
import MembersTable from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/components/MembersTable";
import BasicPage from "@unigow/pages/Functional/BasicPage";
import {ActivityWithParticipants, ReserveWithParticipants} from "@unigow/types/card";
import dayjs from "dayjs";
import React from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {ReactComponent as UnigowLogo} from "@unigow/assets/unigow-black.svg";

export default function CardParticipants(): React.ReactElement {
    const {cardId} = useParams();

    const {data} = useQuery(["cards", cardId], async()=>{
        const results = await api.get<ActivityWithParticipants | ReserveWithParticipants>(`cards/${cardId}`);

        if (results.type === "reservations") {
            const resResults = results as ReserveWithParticipants;

            const today = dayjs();

            resResults.participants = resResults.participants.filter((p)=>dayjs(p.slot.start).isAfter(today)).sort((a, b)=>{
                return dayjs(a.slot.start).diff(dayjs(b.slot.start));
            });
        }

        return results;
    }, {
        enabled: !!cardId
    });

    function renderMembersTable(): React.ReactElement {
        if (!data || !("_id" in data)) return <></>;

        if (data.type === "reservations") {
            return <MembersTable cardSettings={data.settings} height={450} participants={data.participants} type="reservations"/>;
        }

        return <MembersTable cardSettings={data.settings} height={450} participants={data.participants} type="default"/>;
    }

    if (!data) return <div>Loading...</div>;

    return (
        <BasicPage className="flex-col justify-start">
            <div className="w-[95%] lg:w-3/4 mx-auto">
                <div className="flex relative justify-center py-4 w-full">
                    {!window.opener && (
                        <a href="/" className="absolute left-0 translate-y-1/2">
                            &lt; Volver al Dashboard
                        </a>
                    )}
                    <UnigowLogo className="mt-8 lg:mt-0" />
                </div>
                <div className="flex flex-col items-center gap-2">
                    <img src={data.iconUrl} alt={data.name} className="w-20 h-20 rounded-full"/>
                    <h1>Sistema de participantes de {data?.name}</h1>
                    {data.type === "activity" && (
                        <p>Fecha: {dayjs(data.startDate).format("DD [de] MMMM [de] YYYY")}</p>
                    )}
                </div>
                <div>
                    {renderMembersTable()}
                </div>
            </div>
        </BasicPage>
    );
}