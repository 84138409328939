import React from "react";
import PopupWindow from "../../../../components/PopupWindow/PopupWindow";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {setLocalStorage} from "../../../../helpers/localstorage";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {useAuthStore} from "../../../../stores/authStore";

export default function NotificationWindow(): React.ReactElement {
    const {userData} = useAuthStore();
    const {notifications, clearNotifications} = useDashboardStore();

    const [firstNotification] = notifications;

    // Si está en la url del formulario, no aparecer
    if (window.location.pathname.includes("/completeregister") || window.location.pathname.includes("/global") || (!!userData && (!userData.profilePic || userData.profilePic === ""))) return <></>;

    // Check try counter
    const tryCounter = JSON.parse(localStorage.getItem("tryCounter") || "{}") as {[key: string]: number};
    if (firstNotification && tryCounter[firstNotification._id] >= 3) {
        clearNotifications();
        return <></>;
    }

    return (
        <PopupWindow title="Nueva Notificación" maxWidth="sm" open={!!firstNotification} onClose={clearNotifications} closePopup={clearNotifications}>
            {!!firstNotification && (
                <div className="flex flex-col gap-3">
                    <h2>{firstNotification.title}</h2>
                    <p className="text-ug-lg font-normal">{firstNotification.description}</p>
                    <UnigowButton buttonType="anchor" variant="secondary" href={`${firstNotification.url}&notid=${firstNotification._id}`} onClick={()=>{
                        // If they click on the button, dont show the notification again
                        setLocalStorage("tryCounter", JSON.stringify({[firstNotification._id]:3}));
                        clearNotifications();
                    }}
                    >
                        {firstNotification.buttonText || "Quiero participar en este nuevo proyecto"}
                    </UnigowButton>
                </div>
            )}
        </PopupWindow>
    );
}