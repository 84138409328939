import {ArrowBackIosNew, BookmarkOutlined, Logout} from "@mui/icons-material";
import React, {Fragment, useEffect, useState} from "react";

import {ReactComponent as Avatar} from "@unigow/assets/avatarGrey.svg";
import {Divider, IconButton, Skeleton} from "@mui/material";
import {useTwilio} from "@unigow/contexts/TwilioContext";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useChatStore} from "@unigow/components/ChatRoom/store/chatStore";
import {useMediaQuery} from "react-responsive";

type ChatTopProps = ({
    loading: false;
    iconUrl?: string;
    name: string;
    leaveGroup?: ()=> void
} | {
    loading: true;
}) & {
    goBack: ()=> void;
};

export default function ChatTop({goBack, ...props}: ChatTopProps): React.ReactElement {
    const {isDashboard, showAllPinnedQuestions, hidePinnedQuestions, showPinnedQuestions} = useChatStore();
    const {twilioClient} = useTwilio();

    const isMobile = useMediaQuery({query: "(max-width: 768px)"});
    const [typing, setTyping] = useState(false);

    useEffect(()=>{
        function handleTypingOn(): void {
            setTyping(true);
        }

        function handleTypingOff(): void {
            setTyping(false);
        }

        const typingOn = twilioClient?.on("typingStarted", handleTypingOn);
        const typingOff = twilioClient?.on("typingEnded", handleTypingOff);

        return ()=>{
            typingOn?.removeListener("typingStarted", handleTypingOn);
            typingOff?.removeListener("typingEnded", handleTypingOff);
        };
    }, [twilioClient]);

    return (
        <Fragment>
            <div className="flex items-center py-2">
                <IconButton className="mx-4" onClick={goBack}>
                    <ArrowBackIosNew/>
                </IconButton>
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 w-[50px] h-[50px] rounded-full overflow-hidden items-center flex">
                            {props.loading ? (
                                <Skeleton variant="circular" width={50} height={50} animation="wave" className="w-full h-full"/>
                            ) : props.iconUrl ? (
                                <img className="w-full" src={props.iconUrl} alt=""/>
                            ) : (
                                <Avatar height={50} width={50}/>
                            )}
                        </div>
                        <div className="ml-2 lg:ml-5 mr-2 w-full flex flex-col justify-start text-left leading-4 font-semibold gap-2">
                            {!props.loading ? <p className="font-semibold">{props.name}</p> : <Skeleton height={40} variant="text" width="8ch"/>}
                            {typing ? <p className="text-gray-500 text-xs">Escribiendo...</p> : <></>}
                        </div>
                    </div>
                    {!props.loading && !!props.leaveGroup && (
                        <div className="flex gap-4 px-4">
                            {!isMobile && (
                                <UnigowButton variant="basic" className="border-black py-1 flex items-center gap-2" onClick={()=>{
                                    if (showPinnedQuestions.length > 0) {
                                        hidePinnedQuestions();
                                    } else {
                                        showAllPinnedQuestions();
                                    }
                                }}
                                >{showPinnedQuestions.length > 0 ? "Ocultar" : "Ver"} destacados <BookmarkOutlined className="w-4 h-4"/>
                                </UnigowButton>
                            )}

                            {/* SI ESTO SE ROMPE EN IFRAME, BUSCAR ALTERNATIVA */}
                            {!isDashboard && (
                                <UnigowButton variant="basic" className="border-[#D32F2F] text-[#D32F2F] py-1 flex items-center gap-2" onClick={props.leaveGroup}>Salir del grupo <Logout className="w-4 h-4"/></UnigowButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Divider className="w-full"/>
        </Fragment>
    );
}