import {api} from "@unigow/apis/requests";
import {RecursivePartial} from "@unigow/types/custom";
import {IframeWithPlugins} from "@unigow/types/iframe";
import {CardsPluginType, CardType, ChatsPluginType, ClientType, CreateChatsPluginReq, CreateChatsPluginRes, CreateIframeReq, CreateIframeRes, GroupsPluginType, IframeType, UpdateChatsPluginReq, UpdateIframeReq} from "@unigow/unigow-types";

export const IframeAPI = {
    getClientIframes: async function({clientId}: {clientId: string}): Promise<IframeType[]> {
        return api.get<IframeType[]>(`iframes?client=${clientId}`);
    },

    getIframeData: async function({iframeId}: {iframeId: string}): Promise<IframeWithPlugins | undefined> {
        return api.get<IframeWithPlugins | undefined>(`iframes/${iframeId}`);
    },

    getIframeWithClientAndAlias: async function({client, alias}: {client: string, alias: string}): Promise<IframeWithPlugins | undefined> {
        return api.get<IframeWithPlugins | undefined>(`iframes/${client}/iframe/${alias}`);
    },

    createIframe: async function(data: CreateIframeReq): Promise<CreateIframeRes> {
        return api.post<CreateIframeReq, CreateIframeRes>("iframes", data);
    },

    updateIframe: async function({iframeId, data}: {iframeId: string, data: UpdateIframeReq}): Promise<IframeType | undefined> {
        return api.patch<UpdateIframeReq, IframeType | undefined>(`iframes/${iframeId}`, data);
    }
};

export const ChatsPluginAPI = {
    getIframeChatsPlugin: async function({iframeId}: {iframeId: string}): Promise<ChatsPluginType[]> {
        return api.get<ChatsPluginType[]>(`iframes/${iframeId}/plugins/chats`);
    },

    getClientChatsPlugins: async function({clientId}: {clientId: string}): Promise<ChatsPluginType[]> {
        return api.get<ChatsPluginType[]>(`pluginchats?client=${clientId}`);
    },

    getAllChatsPlugins: async function(): Promise<(ChatsPluginType & {clientInfo: Pick<ClientType, "_id" | "alias" | "name">})[]> {
        return api.get<(ChatsPluginType & {clientInfo: Pick<ClientType, "_id" | "alias" | "name">})[]>("pluginchats");
    },

    createChatsPlugin: async function({iframe, data}: {iframe: string, data: CreateChatsPluginReq}): Promise<CreateChatsPluginRes> {
        const url = `iframes/${iframe}/plugins/chats`;

        return api.post<CreateChatsPluginReq, CreateChatsPluginRes>(url, data);
    },

    updateChatsPlugin: async function({pluginId, data}: {pluginId: string, data: UpdateChatsPluginReq}): Promise<ChatsPluginType | undefined> {
        const url = `pluginchats/${pluginId}`;

        return api.patch<UpdateChatsPluginReq, ChatsPluginType | undefined>(url, data);
    }
};

export const GroupsPluginAPI = {
    getIframeGroupsPlugin: async function({iframeId}: {iframeId: string}): Promise<GroupsPluginType[]> {
        return api.get<GroupsPluginType[]>(`iframes/${iframeId}/plugins/groups`);
    }
};

export const CardsPluginAPI = {
    getIframeCardsPlugin: async function({iframeId}: {iframeId: string}): Promise<CardsPluginType[]> {
        return api.get<CardsPluginType[]>(`iframes/${iframeId}/plugins/cards`);
    },
    updateCard: async function({cardId, data}: {cardId: string, data: RecursivePartial<CardType>}): Promise<CardType | undefined> {
        return api.patch<RecursivePartial<CardType>, CardType | undefined>(`cards/${cardId}`, data);
    }
};