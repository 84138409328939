import {XMarkIcon} from "@heroicons/react/24/outline";
import {Dialog, DialogContent, DialogProps, DialogTitle, IconButton} from "@mui/material";
import React from "react";

export interface PopupWindowProps extends DialogProps {
    children: React.ReactNode;
    closePopup?: ()=> void;
    title: string;
}

function PopupWindow({children, closePopup, title, ...props}: PopupWindowProps): React.ReactElement {
    return (
        <Dialog {...props} scroll="paper" className="shadow-ug-lg" PaperProps={{style:{borderRadius:"20px"}}}>
            <div className="flex items-center px-2 justify-between">
                <DialogTitle className="mt-4 py-0 flex items-center">
                    <p className="line-clamp-1 w-full text-primary-500">{title}</p>
                </DialogTitle>
                <div className="flex justify-end py-2">
                    {closePopup && (
                        <IconButton className="cursor-pointer" onClick={closePopup}>
                            <XMarkIcon width={20} height={20}/>
                        </IconButton>
                    )}
                </div>
            </div>
            <DialogContent className="px-[30px] flex flex-col items-center">
                {children}
            </DialogContent>
        </Dialog>
    );
}

export default PopupWindow;