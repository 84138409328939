import "./styles.css";

import React, {Children, useRef} from "react";
import {Helmet} from "react-helmet";

import {useTwilio} from "@unigow/contexts/TwilioContext";
import {TwilioGroupChat} from "../../types/chat";
import {api} from "../../apis/requests";
import ChatTop from "@unigow/components/ChatRoom/components/ChatTop";
import {useQuery} from "react-query";
import ChatBottom from "@unigow/components/ChatRoom/components/ChatBottom";
import ChatBox from "@unigow/components/ChatRoom/components/ChatBox";
import {useChatStore} from "@unigow/components/ChatRoom/store/chatStore";
import {ChatRoomProps} from "@unigow/components/ChatRoom/ChatRoom";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {Button} from "@mui/material";
import {twMerge} from "tailwind-merge";
import {CSSTransition} from "react-transition-group";

export default function GroupRoom({sid, goBack, dashboard}: ChatRoomProps): React.ReactElement {
    // STORES
    const {setConv, setIsDashboard, showPinnedQuestions, setPinnedQuestions, pinnedQuestions, showAllPinnedQuestions,
        hidePinnedQuestions} = useChatStore();
    const {twilioClient} = useTwilio();

    const pinnedQuestionsRef = useRef<HTMLDivElement>(null);

    const {data:convInfo, isSuccess:mongoLoaded} = useQuery(["chat", sid], async()=>{
        const data = await api.get<TwilioGroupChat>(`groups/group/${sid}`);

        return {
            ...data,
            type:"group" as const
        };
    }, {
        enabled:!!sid,
        onSuccess:(group)=>{
            if (!group) return;
            setPinnedQuestions(group.pinnedQuestions);
        }
    });

    const {data:currentConv, isSuccess:twilioLoaded} = useQuery(["conversation", sid], ()=>{
        return twilioClient?.getConversationBySid(sid);
    }, {
        enabled:!!sid && !!twilioClient,
        onError:()=>{
            window.location.reload();
        },
        onSuccess:(conversation)=>{
            setIsDashboard(dashboard);
            setConv(conversation);
        }
    });

    async function leaveGroup(): Promise<void> {
        if (!twilioClient || !currentConv || !convInfo) return;

        await api.post<void, {status: string}>(`groups/leave/${convInfo._id}`);

        window.location.reload();
    }

    return (
        <>
            <div className="bg-white w-full rounded-[20px]">
                <Helmet>
                    <title>Unigow - Grupo de {`${convInfo?.name}`}</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                {(!mongoLoaded || !twilioLoaded) ? (
                    <ChatTop goBack={goBack} loading={true}/>
                ) : (
                    <ChatTop goBack={goBack} loading={false} name={convInfo.name} iconUrl={convInfo.iconUrl} leaveGroup={leaveGroup}/>
                )}
                <div className="relative">
                    <div className={twMerge("absolute w-[calc(100%-3rem)] px-4 bg-[#F2F8FF] z-10 flex flex-col gap-2 max-h-[50vh] overflow-y-auto py-4 border-solid border border-business-500 rounded-[4px] my-2 mx-2", showPinnedQuestions.length === 0 ? "py-0" : "")}>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-4">
                                <div className="w-6 h-6 rounded-full bg-business-500 text-sm text-white text-center flex justify-center items-center">{pinnedQuestions.length}</div>
                                <p className="font-medium">Lee los Destacados antes de preguntar</p>
                            </div>
                            <Button variant="text" className="italic text-business-500 font-semibold text-ug-lg underline leading-tight" sx={{textTransform:"unset"}}
                                onClick={(()=>{
                                    if (showPinnedQuestions.length > 0) {
                                        hidePinnedQuestions();
                                    } else {
                                        showAllPinnedQuestions();
                                    }
                                })}
                            >{showPinnedQuestions.length > 0 ? "Ocultar" : "Mostrar"} Destacados
                            </Button>
                        </div>
                        <CSSTransition nodeRef={pinnedQuestionsRef} classNames="pinnedquestions" timeout={500} in={showPinnedQuestions.length > 0} unmountOnExit>
                            <div ref={pinnedQuestionsRef} className="flex flex-col transition-all overflow-auto">
                                <ul className="flex flex-col gap-2">
                                    {Children.toArray(showPinnedQuestions.map((question)=>{
                                        return (
                                            <li className="bg-white rounded-[4px] p-4 flex flex-col gap-2">
                                                <div className="flex flex-col gap-1">
                                                    <p className="text-gray-cool-400 font-semibold text-sm">Pregunta destacada</p>
                                                    <p className="font-medium">{question.question}</p>
                                                </div>
                                                <div className="flex flex-col gap-1">
                                                    <p className="text-gray-cool-400 font-semibold text-sm">Respuesta destacada</p>
                                                    <p className="font-medium">{question.answer}</p>
                                                </div>
                                            </li>
                                        );
                                    }
                                    ))}
                                </ul>
                            </div>
                        </CSSTransition>
                        {showPinnedQuestions.length > 0 && pinnedQuestions.length !== showPinnedQuestions.length && (
                            <div className="flex justify-center">
                                <UnigowButton variant="basic" onClick={showAllPinnedQuestions}>Ver todas las preguntas destacadas</UnigowButton>
                            </div>
                        )}
                    </div>
                    {(!convInfo || !currentConv) ? (
                        <div className="flex flex-col gap-4 overflow-y-scroll p-4 h-[50vh]">

                        </div>
                    ) : (
                        <ChatBox group chatInfo={convInfo}/>
                    )}
                </div>

                {currentConv && convInfo && (
                    <ChatBottom chatInfo={convInfo}/>
                )}
            </div>
        </>
    );
}