import {IframeAPI} from "@unigow/apis/iframe";
import {useIframe} from "./contexts/IframeContext";
import {useIframeStore} from "@unigow/stores/iframeStore";
import React, {useEffect} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import ChatRoom from "@unigow/components/ChatRoom/ChatRoom";
import {Loading} from "@unigow/pages/Loading/Loading";
import IframeContainer from "@unigow/components/Iframe/IframeContainer";
import Chats from "./pages/Chats";
import Profile from "./pages/Profile";
import IframeLogin from "./pages/Login";
import IframeRegister from "./pages/Register";
import IframeQuestions from "./pages/Form/Questions";
import Search from "@unigow/pages/Iframe/pages/Search";
import GroupsSearch from "@unigow/pages/Iframe/pages/GroupsSearch";
import GroupRoom from "@unigow/components/ChatRoom/GroupRoom";
import {checkLoginSchema, checkRegisterSchema, convertUserInfoParams, sendResponseToParent} from "@unigow/helpers/iframe";
import {FirebaseError} from "firebase/app";
import {toast} from "react-toastify";
import {api} from "@unigow/apis/requests";
import {useAuth} from "@unigow/contexts/AuthContext";
import {useAuthStore} from "@unigow/stores/authStore";
import CardsSearch from "@unigow/pages/Iframe/pages/CardsSearch";
import SlotPicker from "@unigow/pages/Iframe/pages/Form/SlotPicker";

export default function Iframe(): React.ReactElement {
    const {registerUser, loginUser} = useAuth();
    const {modifyUserData} = useAuthStore();
    const {iframeId, client, iframe} = useParams();

    const {setIframeData, iframeData} = useIframeStore();
    const {currentPage, setCurrentPage} = useIframe();

    useQuery(["iframe", iframeId], async()=>{
        return IframeAPI.getIframeData({iframeId:iframeId!});
    }, {enabled:!!iframeId,
        onSuccess:(data)=>{
            if (!data) return;

            setIframeData(data);
        }}
    );

    useQuery(["clientiframe", client, iframe], async()=>{
        return IframeAPI.getIframeWithClientAndAlias({client:client!, alias:iframe!});
    }, {
        enabled:!!client && !!iframe,
        onSuccess:(data)=>{
            if (!data) return;

            setIframeData(data);
        }
    });

    useEffect(()=>{
        async function handleAuthMessages(event: MessageEvent): Promise<void> {
            if (!event.isTrusted) return;

            const {data} = event as MessageEvent<unknown>;

            if (!data || typeof data !== "object") {
                sendResponseToParent({status:"error", method:"none", details:"No data received"});
                return;
            }

            const {method} = data as {method: string};

            if (!["register", "login"].includes(method)) {
                // Solo aceptar los métodos de login y register
                sendResponseToParent({status:"error", method:"none", details:"Invalid method"});
                return;
            }

            if (method === "register") {
                try {
                    const registerData = checkRegisterSchema(data);

                    // Si faltan campos, indicar cuales
                    if (Array.isArray(registerData)) {
                        sendResponseToParent({status:"error", method:"register", details:`Missing fields: ${registerData.join(", ")}`});
                        return;
                    }

                    const {name, surnames, email, password, phone, ...userInfo} = registerData;

                    const response = await registerUser({name, surnames, email, phone}, password).catch((e)=>{
                        const error = e as FirebaseError;
                        if (error.code === "auth/email-already-in-use") {
                            toast.error("Esa cuenta ya existe", {className:"unigow-toast"});
                        } else {
                            toast.error("Ha habido un error inesperado, inténtalo más tarde", {className:"unigow-toast"});
                        }
                        sendResponseToParent({status:"error", method:"register", details:error.message});
                        return;
                    });

                    if (!response) {
                        return;
                    }

                    const userInf = userInfo as Record<string, unknown>;

                    // Reemplazar todos los _ por espacios en las claves del objeto
                    for (const key in userInf) {
                        const newKey = key.replace(/_/g, " ");
                        userInf[newKey] = userInf[key];

                        if (newKey !== key) {
                            delete userInf[key];
                        }
                    }

                    const createUserInfo = {
                        info:convertUserInfoParams(userInf)
                    };

                    await new Promise((resolve)=>setTimeout(resolve, 300));

                    const newInfo = await api.post<{info: Record<string, string | string[]>}, {info: Record<string, string>}>("userinfo", createUserInfo).catch(()=>{
                        sendResponseToParent({status:"error", method:"register", details:"Error adding additional info"});
                    });

                    if (newInfo) {
                        modifyUserData("additionalInfo", newInfo.info);
                    }

                    sendResponseToParent({status:"ok", method:"register"});
                } catch (e) {
                    const err = e as Error;

                    sendResponseToParent({status:"error", method:"register", details:err.message});
                }
            } else if (method === "login") {
                const loginData = checkLoginSchema(data);

                // Si faltan campos, indicar cuales
                if (Array.isArray(loginData)) {
                    sendResponseToParent({status:"error", method:"login", details:`Missing fields: ${loginData.join(", ")}`});
                    return;
                }

                const {email, password} = loginData;

                await loginUser(email, password).catch((e)=>{
                    const error = e as FirebaseError;
                    sendResponseToParent({status:"error", method:"login", details:error.message});
                });

                sendResponseToParent({status:"ok", method:"login"});
            }
        }

        // Listener for messages
        window.addEventListener("message", handleAuthMessages);

        return ()=>window.removeEventListener("message", handleAuthMessages);
    }, [registerUser, loginUser, modifyUserData]);

    function renderPlugin(pluginId: string): React.ReactElement {
        if (!iframeData) return <></>;

        const pluginData = iframeData.pluginData.find((plugin)=>plugin._id.toString() === pluginId);

        if (!pluginData) return <></>;

        switch (pluginData.type) {
            case "chat":{
                return <Search type="mentor" pluginData={{...pluginData, clientInfo:iframeData.clientInfo}}/>;
            }

            case "group":{
                return <GroupsSearch pluginData={pluginData} iframeData={iframeData}/>;
            }

            case "cards":{
                return <CardsSearch pluginData={pluginData} iframeData={iframeData}/>;
            }

            default:{
                return <></>;
            }
        }
    }

    function getComponent(): React.ReactElement {
        switch (currentPage) {
            case "chats":
                return (
                    <Chats />
                );
            case "profile":
                return (
                    <Profile/>
                );
            case "login":{
                return (
                    <IframeLogin/>
                );
            }
            case "register":{
                return (
                    <IframeRegister/>
                );
            }
            case "form":
                return (
                    <IframeQuestions/>
                );
            case "slotpick":
                return <SlotPicker/>;
            default:{
                // Because this router depends on a string for getting the pages, the dynamic element with chats is a bit weird
                // Basically the chat page is defined by the SID by setting the "currentPage" to "chat-[SID]" so that it can be split.
                // There is no risk for the character "-" to be in the SID because it is only composed by alphanumeric characters.
                if (currentPage.includes("chat-")) {
                    return <ChatRoom sid={currentPage.split("-")[1]} goBack={()=>setCurrentPage("chats")} dashboard={false}/>;
                }

                if (currentPage.includes("group-")) {
                    const [,groupId] = currentPage.split("-");

                    return <GroupRoom sid={groupId} goBack={()=>setCurrentPage("chats")} dashboard={false}/>;
                }

                if (currentPage.includes("plugin-")) {
                    const [,pluginId] = currentPage.split("-");

                    return renderPlugin(pluginId);
                }
                // return <Search type="mentor" iframeData={iframeData}/>;
                return <></>;
            }
        }
    }

    if (!iframeData) {
        return <Loading color="text-gray-cool-400"/>;
    }

    return (
        <IframeContainer loading={!iframeData}>
            {getComponent()}
        </IframeContainer>
    );
}