import {PickersDay, StaticDatePicker} from "@mui/x-date-pickers";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {Dayjs} from "dayjs";
import React, {Children, Fragment, useState} from "react";

interface ExcludedDaysSelectorProps {
    value: Dayjs[];
    onChange: (value: Dayjs[])=> void;
}

export default function ExcludedDaysSelector({value, onChange}: ExcludedDaysSelectorProps): React.ReactElement {
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

    return (
        <Fragment>
            <UnigowButton type="button" variant="basic" onClick={()=>{
                setOpen(true);
            }}
            >Elegir días excepcionales
            </UnigowButton>
            <PopupWindow title="Selecciona los días en los que no se podrá reservar" open={open} onClose={()=>setOpen(false)} maxWidth="md">
                <div className="flex flex-row-reverse gap-4">
                    <div className="flex flex-col gap-2 w-[300px]">
                        <p className="font-semibold">Días seleccionados</p>
                        <ul className="flex flex-wrap gap-2 text-sm">
                            {Children.toArray(value.map((date)=>{
                                return (
                                    <li>{date.locale("es").format("DD [de] MMMM")}</li>
                                );
                            }))}
                        </ul>
                    </div>
                    <StaticDatePicker
                        className="shrink-0"
                        openTo="day"
                        value={selectedDate}
                        slotProps={{toolbar:{hidden:true},
                            actionBar:{actions:[]}}}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                        views={["day", "month"]}
                        slots={{
                            day: ({day, ...props})=>{
                                const isSelected = value.some((date)=>date.isSame(day, "day"));

                                return (
                                    <PickersDay
                                        {...props}
                                        day={day}
                                        onDaySelect={()=>{
                                            setSelectedDate(day);
                                            if (value.some((date)=>date.isSame(day, "day"))) {
                                                onChange(value.filter((date)=>!date.isSame(day, "day")));
                                            } else {
                                                onChange([...value, day]);
                                            }
                                        }}
                                        selected={isSelected}
                                    />
                                );
                            }
                        }}
                    />
                </div>
                <div className="flex gap-2 justify-end w-full">
                    <UnigowButton variant="basic" onClick={()=>setOpen(false)}>Cancelar</UnigowButton>
                    <UnigowButton variant="secondary" onClick={()=>{
                        setOpen(false);
                    }}
                    >Continuar
                    </UnigowButton>
                </div>
            </PopupWindow>
        </Fragment>
    );
}