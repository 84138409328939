import {Paper, TableContainer} from "@mui/material";
import {DataGrid, DataGridProps} from "@mui/x-data-grid";
import React from "react";

interface UnigowGridProps {
    elementCount: number;
    height?: number;
}

export function UnigowGrid({columns, rows, elementCount, height, ...gridProps}: UnigowGridProps & DataGridProps): React.ReactElement {
    return (
        <Paper sx={{width: "100%", overflow: "hidden", boxShadow:"none"}}>
            <TableContainer component={Paper} sx={{maxHeight: height || 700}}>
                <div className="px-4 py-2 text-gray-cool-500">
                    <p>{elementCount} elementos</p>
                </div>
                <DataGrid {...gridProps} rows={rows} columns={columns}/>
            </TableContainer>
        </Paper>
    );
}