import {isValidPhoneNumber} from "libphonenumber-js/max";
import {IframeWithPlugins} from "@unigow/types/iframe";

export async function resizeIframe({height, width, animate}: {height: number, width?: number, animate?: boolean}): Promise<void> {
    if (animate) {
    // Wait 300 ms for animations
        await new Promise((resolve)=>setTimeout(resolve, 300));
    }

    parent.postMessage({action:"resize", data:{height, width}}, "*");
}

export function iframeReady(): void {
    parent.postMessage({action:"ready"}, "*");
}

type AutoRegisterSchema = {
    name: string;
    surnames: string;
    email: string;
    curso_escolar: string;
    center?: string;
    phone?: string;
    age: number;
    province: string;
    password: string;
};

type AutoLoginSchema = {
    email: string;
    password: string;
};

export function checkRegisterSchema(data: object): AutoRegisterSchema | string[] {
    const {name, surnames, email, password, center, phone, age, province, curso_escolar} = data as AutoRegisterSchema;

    // Check the data for every field exists, if not return only the missing fields
    const missingFields = [];

    if (!name) missingFields.push("name");
    if (!surnames) missingFields.push("surnames");
    if (!email) missingFields.push("email");
    // OPTIONAL
    // if (!center) missingFields.push("center");
    // if (!age) missingFields.push("age");
    // if (!province) missingFields.push("province");
    // if (!curso_escolar) missingFields.push("curso_escolar");
    if (!password) missingFields.push("password");

    if (!!phone && !isValidPhoneNumber(phone)) throw new Error("Invalid phone number");

    if (missingFields.length) return missingFields;

    return {name, surnames, email, center, phone, age, curso_escolar, province, password};
}

export function checkLoginSchema(data: object): AutoLoginSchema | string[] {
    const {email, password} = data as AutoLoginSchema;

    // Check the data for every field exists, if not return only the missing fields
    const missingFields = [];

    if (!email) missingFields.push("email");
    if (!password) missingFields.push("password");

    if (missingFields.length) return missingFields;

    return {email, password};
}

export function sendResponseToParent({status, method, details}: {status: "ok" | "error", method: "login" | "register" | "none", details?: string}): void {
    parent.postMessage({result:status, method, details}, "*");
}

export function convertUserInfoParams(receivedObject: Record<string, unknown>): Record<string, string | string[]> {
    const userInfo: Record<string, string | string[]> = {};

    const keys = Object.keys(receivedObject);

    for (const key of keys) {
        switch (key) {
            case "age":{
                userInfo.edad = (receivedObject[key] as number).toString();
                break;
            }
            case "center":{
                userInfo["centro educativo"] = receivedObject[key] as string;
                break;
            }
            case "province":{
                userInfo.provincia = receivedObject[key] as string;
                break;
            }
            default:{
                userInfo[key] = receivedObject[key] as string;
                break;
            }
        }
    }

    return userInfo;
}

export function getPluginData(pluginId: string, plugins: IframeWithPlugins["pluginData"]): IframeWithPlugins["pluginData"][0] | undefined {
    return plugins.find((plugin)=>{
        return plugin._id.toString() === pluginId;
    });
}

export function getIframeUserParams(iframe: IframeWithPlugins): string[] {
    const params: string[] = [];

    for (const plugin of iframe.pluginData) {
        // If settings property does not exist, continue
        if (!("settings" in plugin)) continue;

        for (const param of plugin.settings.userParams) {
            if (!params.includes(param)) {
                params.push(param);
            }
        }
    }

    return params;
}