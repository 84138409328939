import {createTheme, ThemeProvider} from "@mui/material";
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {UnigowGrid} from "@unigow/components/UnigowTable/UnigowGrid";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import {generateCsv} from "@unigow/helpers/helper";
import {ActivityWithParticipants, ReserveWithParticipants} from "@unigow/types/card";
import {CardType} from "@unigow/unigow-types";
import dayjs from "dayjs";
import React from "react";

interface MembersTableProp {
    cardSettings: CardType["settings"];

    participants: ActivityWithParticipants["participants"] | ReserveWithParticipants["participants"];

    type: "default" | "reservations";

    height?: number;
}

export default function MembersTable({cardSettings, participants, type, height}: MembersTableProp): React.ReactElement {
    const {t} = useCustomLocale();

    const minorsPresent = participants.some((participant)=>participant.minor);

    function slotToString(slot: ReserveWithParticipants["participants"][number]["slot"]): string {
        return `${new Date(slot.start).toLocaleString("es-ES", {hour:"2-digit", minute:"2-digit"})} -> ${new Date(slot.end).toLocaleString("es-ES", {hour:"2-digit", minute:"2-digit"})}`;
    }

    function generateRows(): GridRowsProp {

        return participants.map((participant, i)=>{
            const userInfoRows = cardSettings.userParams;

            const userInfoParams = userInfoRows.map((row)=>{
                if (!participant.info) return {field: row, value: ""};

                return {field: row, value: participant.info[row]};
            });

            if ("slot" in participant) {
                let part = {id: i, date: dayjs(participant.slot.start).format("ddd, DD [de] MMMM"), slot: slotToString(participant.slot),
                    name: `${participant.name} ${participant.surnames}`, email: participant.email,
                    phone: participant.phone} as Record<string, unknown>;

                if (minorsPresent && participant.minor) {
                    part = {...part, parentName: `${participant.parentName} ${participant.parentSurnames}`};
                }

                return {...part, ...userInfoParams.reduce((acc, row)=>({...acc, [row.field]: row.value}), {})};
            }
            const part =  {id: participant._id, name: `${participant.name} ${participant.surnames}`, email: participant.email, phone: participant.phone, ...userInfoParams};

            if (minorsPresent && participant.minor) {
                return {...part, parentName: `${participant.parentName} ${participant.parentSurnames}`};
            }

            return {...part, ...userInfoParams.reduce((acc, row)=>({...acc, [row.field]: row.value}), {})};
        });
    }

    function generateHeaders(): GridColDef[] {
        const userInfoRows = cardSettings.userParams;

        if (type === "reservations") {
            return [
                {field: "date", headerName: "Fecha", flex: 1},
                {field: "slot", headerName: "Horario", flex: 1},
                {field: "name", headerName: "Nombre", flex: 1},
                ...(minorsPresent ? [{field: "parentName", headerName: "Nombre del padre/madre/tutor", flex: 1}] : []),
                {field: "email", headerName: "Email", flex: 1},
                {field: "phone", headerName: "Teléfono", flex: 1},
                ...userInfoRows.map((row)=>({field: row, headerName: t(row), flex: 1}))
            ];
        }

        return [
            {field: "name", headerName: "Nombre", flex: 1},
            {field: "email", headerName: "Email", flex: 1},
            {field: "phone", headerName: "Teléfono", flex: 1},
            ...userInfoRows.map((row)=>({field: row, headerName: t(row), flex: 1}))
        ];
    }

    function downloadParticipants(): void {
        const headers = generateHeaders().map((header)=>header.field);

        const data = generateRows().map((row)=>headers.map((header)=>row[header] as string));

        generateCsv(headers, data, "participants.csv");
    }

    const myTheme = createTheme({
        components: {
            //@ts-expect-error - this isn't in the TS because DataGird is not exported from `@mui/material`
            MuiDataGrid: {
                styleOverrides: {
                    row: {
                        "&.Mui-selected": {
                            textDecoration: "line-through"
                        }
                    }
                }
            }
        }
    });

    return (
        <div className="flex flex-col py-8">
            <div className="flex justify-end">
                <UnigowButton variant="secondary" onClick={downloadParticipants}>Descargar CSV</UnigowButton>
            </div>
            <ThemeProvider theme={myTheme}>
                <UnigowGrid checkboxSelection columns={generateHeaders()} rows={generateRows()} elementCount={participants.length} height={height}/>
            </ThemeProvider>
        </div>
    );
}