import {UnigowError, UnigowHttpError} from "@unigow/types/error";
import {getLocalStorage} from "../helpers/localstorage";

async function request<TResponse>(url: string, config: RequestInit): Promise<TResponse> {
    let token = getLocalStorage("firebase_token");

    if (token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
        };
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/v3/${url}`, config);
    if (response.status > 399) {
        const errorResponse = await response.json() as UnigowError;
        if (errorResponse.errorCode === "auth/invalid-token") {
            // Retry the request
            token = getLocalStorage("firebase_token");

            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                };
            }

            const retryResponse = await fetch(`${process.env.REACT_APP_API_URL}/v3/${url}`, config);

            if (retryResponse.status > 399) {
                const retryErrorResponse = await retryResponse.json() as UnigowError;
                if (retryErrorResponse.errorCode === "auth/invalid-token") {
                    localStorage.removeItem("firebase_token");
                    window.location.reload();
                }
            }

            return retryResponse.json() as TResponse;
        }
        throw new UnigowHttpError(errorResponse);
    }
    return response.json() as TResponse;
}

export const api = {
    get: <TResponse>(url: string): Promise<TResponse> =>
        request<TResponse>(url, {method:"GET"}),

    delete: <TResponse>(url: string): Promise<TResponse> =>
        request<TResponse>(url, {method:"DELETE"}),

    post: <TBody, TResponse>(url: string, body?: TBody, keepAlive?: boolean): Promise<TResponse> =>
        request<TResponse>(url, {method: "POST", body:JSON.stringify(body), headers:{"Content-Type":"application/json"}, keepalive:keepAlive}),

    patch: <TBody, TResponse>(url: string, body: TBody, keepAlive?: boolean): Promise<TResponse> =>
        request<TResponse>(url, {method: "PATCH", body:JSON.stringify(body), headers:{"Content-Type":"application/json"}, keepalive:keepAlive})
};