import {Loading} from "@unigow/pages/Loading/Loading";
import React, {useEffect, useRef, useState} from "react";

import {ReactComponent as Unigow} from "@unigow/assets/unigow-black.svg";
import {twMerge} from "tailwind-merge";
import {useIframeStore} from "@unigow/stores/iframeStore";
import IframeHeader from "@unigow/pages/Iframe/components/IframeHeader";

interface IframeContainerProps {
    loading: boolean;
    landing?: boolean;
    children: React.ReactElement;
}


export default function IframeContainer({loading, landing, children}: IframeContainerProps): React.ReactElement {
    const {iframeData} = useIframeStore();

    const [height, setHeight] = useState(0);

    const containerRef = useRef<HTMLDivElement>(null);

    // EFFECTS
    useEffect(()=>{
        // Use effect para cambiar el tamaño del iframe en caso de que se reciba un mensaje
        function startMessageListener(event: MessageEvent): void {
            if (event.data.height) {
                setHeight(event.data.height);
            }
        }

        window.addEventListener("message", startMessageListener);

        // wait for the parent to be ready
        setTimeout(()=>{
            window.parent.postMessage({message:"ready"}, "*");
        }, 500);

        // Clean the listener
        return ()=>window.removeEventListener("message", startMessageListener);
    }, []);

    useEffect(()=>{
        const container = containerRef.current;

        if (!iframeData || !container) return;

        container.style.backgroundColor = iframeData.style.backgroundColor || "white";
    }, [iframeData]);

    if (!iframeData) return <></>;

    return (
        <div ref={containerRef} className={twMerge("m-auto flex flex-col relative overflow-hidden", landing ? "items-center pb-8 w-full" : "max-w-6xl")} style={height ? {height} : {}}>
            <div className="w-full bg-white z-30">
                <IframeHeader iframeData={iframeData} backgroundColor={iframeData.style.backgroundColor}/>
                <hr className={"bg-primary-500 w-full m-auto h-[2px] border-none"}/>
            </div>
            <div className="mt-8 lg:w-10/12 w-full mx-auto overflow-y-auto">
                {loading ? (
                    <Loading iframe/>
                ) : children}
            </div>
            <div className="py-2 w-max mx-auto">
                <a href="https://unigow.com/" rel="noopener noreferrer" target="_blank" className="flex justify-center items-center no-underline">
                    <p className="text-ug-sm font-semibold text-gray-cool-300">Powered by</p>
                    <Unigow width="80px"/>
                </a>
            </div>
        </div>
    );
}