import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../pages/Loading/Loading";
import {useAuthStore} from "../../stores/authStore";
import {deleteCookie, getCookie, setCookie} from "@unigow/helpers/localstorage";

interface ProtectedRouteProps {
    children: React.JSX.Element
}

function ProtectedRoute({children}: ProtectedRouteProps): React.ReactElement {
    // MISCELANEOUS
    const navigate = useNavigate();

    // STORES
    const {userData, loading} = useAuthStore();

    useEffect(()=>{
        if (!userData && !loading) {
            // El usuario no ha iniciado sesión

            const currentUrl = window.location.href;

            setCookie("redirect", currentUrl, 1);

            window.location.href = "/login";
        }

        if (userData && !loading) {
            // El usuario ha iniciado sesión

            const redirectTo = getCookie("redirect");
            deleteCookie("redirect");

            if (redirectTo) {
                // Remove domain from url
                const url = new URL(redirectTo);
                const path = url.pathname;

                navigate(path);
            }
        }
    }, [loading, userData, navigate]);

    if (loading || !userData) return <Loading/>;

    return children;
}

export default ProtectedRoute;