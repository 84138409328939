import {ChatsPluginType, MinChatsPlugin} from "@unigow/unigow-types";
import {minLength} from "class-validator";
import {toast} from "react-toastify";
import {create} from "zustand";

type ChatsPluginStore = {
    // Plugins disponibles para el iframe
    availablePlugins: MinChatsPlugin[];

    // Función para actualizar los plugins disponibles
    setAvailablePlugins: (data: MinChatsPlugin[])=> void;

    // Función para añadir un nuevo plugin a la lista de plugins disponibles
    addNewPlugin: (plugin: MinChatsPlugin)=> void;

    // Id del plugin que se está configurando
    selectedPlugin: string | undefined;

    // Función para seleccionar un plugin
    selectPlugin: (id: string | undefined)=> void;

    // Datos del plugin de chats
    pluginData: ChatsPluginType | undefined

    // Función para actualizar el plugin de chats
    setPluginData: (data: ChatsPluginType | undefined)=> void;

    // Función para modificar un parámetro de la información del plugin seleccion
    modifyPluginData: <K extends keyof ChatsPluginType>(property: K, value: ChatsPluginType[K])=> void;

    // Paso actual del wizard
    step: number;

    // Función para actualizar el paso del wizard
    setStep: (step: number)=> void;

    // Página actual del wizard
    configPage: "config" | "popups"

    // Función para actualizar la página actual del wizard
    setConfigPage: (page: "config" | "popups")=> void;

    resetPluginData: ()=> void;
};

export const useChatsPluginStore = create<ChatsPluginStore>((set, get) => ({
    availablePlugins: [],
    setAvailablePlugins:(data)=>{
        set({availablePlugins:data});
    },
    addNewPlugin:(plugin)=>{
        const {availablePlugins} = get();
        set({availablePlugins:[...availablePlugins, plugin]});
    },
    selectedPlugin: undefined,
    selectPlugin:(id)=>{
        set({selectedPlugin:id});
    },
    pluginData: undefined,
    setPluginData:(data)=>{
        set({pluginData:data});
    },
    modifyPluginData:(property, value)=>{
        const {pluginData} = get();
        if (!pluginData) return;

        const updatedPluginData = {
            ...pluginData,
            [property]:value
        };

        set({pluginData:updatedPluginData});
    },
    step: 0,
    setStep:(step)=>{
        if (step === 1) {
            set({step:1});
            return;
        }

        const {pluginData} = get();

        if (!pluginData || !minLength(pluginData.alias, 1) || !pluginData.settings.contact) {
            toast.error("Debes rellenar la información básica del Plugin en el paso 1 primero.");
            return;
        }

        if (step === 2) {
            set({step:2});
            return;
        }

        if (!pluginData.settings.mainField) {
            toast.error("Debes definir el nombre de la categoría principal en el paso 2 primero.");
            return;
        }

        if (step > 2) set({step:step});
    },
    configPage: "config",
    setConfigPage:(page)=>{
        set({step:0});
        set({configPage:page});
    },
    resetPluginData:()=>{
        set({pluginData:undefined});
        set({selectedPlugin:undefined});
    }
}));