import React, {createContext, useContext} from "react";

import {api} from "../apis/requests";
import {useQuery} from "react-query";

interface CustomLocaleContextProps {
    children: React.ReactNode;
}

export type CustomLocaleContextType = {
    t: (v: string)=> string,
    getType: (v: string)=> "free" | "general" | "specific",
    getInputType: (v: string)=> "text" | "checkbox" | "policy",
    getRequiredParams: (params: string[], minor: boolean)=> string[],

    ready: boolean;
};

export const CustomLocaleContext = createContext<CustomLocaleContextType>({} as CustomLocaleContextType);

export function useCustomLocale(): CustomLocaleContextType {
    return useContext(CustomLocaleContext);
}

const defaultTranslations = {
    "monday": "Lunes",
    "tuesday": "Martes",
    "wednesday": "Miercoles",
    "thursday": "Jueves",
    "friday": "Viernes",
    "saturday": "Sabado",
    "sunday": "Domingo"
};


export function CustomLocaleProvider({children}: CustomLocaleContextProps): React.ReactElement {
    const {data:loc, isLoading, isSuccess} = useQuery("locale", async()=>{
        return api.get<Record<string, {name: string, type: "free" | "general" | "specific", inputType: "text" | "checkbox" | "policy", required: boolean, minors: boolean}>>("parameters/locale");
    }, {refetchOnWindowFocus:false});

    function translate(v: string): string {
        if (!loc) return v;

        // Search in the default translations
        if (v in defaultTranslations) return defaultTranslations[v as keyof typeof defaultTranslations];

        if (!loc[v]) return v;

        return loc[v].name || v;
    }

    function getRequiredParams(params: string[], minor: boolean): string[] {
        if (!loc) return [];

        // Check if the param is required
        const onlyRequired = params.filter((p)=>loc[p]?.required);

        // If the user is a minor, return all params
        if (minor) return onlyRequired;

        // If the user is not a minor, return only the params that are not for minors
        return onlyRequired.filter((p)=>!loc[p]?.minors);
    }

    function getType(v: string): "free" | "general" | "specific" {
        if (!loc || !loc[v]) return "general";
        return loc[v].type || "general";
    }

    function getInputType(v: string): "text" | "checkbox" | "policy" {
        if (!loc || !loc[v]) return "text";
        return loc[v].inputType || "text";
    }

    return (
        <CustomLocaleContext.Provider value={{t:translate, getType, getInputType, ready:!isLoading &&
        isSuccess, getRequiredParams}}
        >
            {children}
        </CustomLocaleContext.Provider>
    );
}