import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Dashboard from "./pages/Dashboard/Dashboard";
import ProtectedLayout from "./components/Protected/ProtectedLayout";
import Iframes from "./pages/Dashboard/pages/Iframes/Iframes";
import ChatsPage from "./pages/Dashboard/pages/ChatsPage/ChatsPage";
import OrientatorRegister from "./pages/OrientatorRegister/OrientatorRegister";
import Stats from "./pages/Dashboard/pages/Stats/Stats";
import Logs from "./pages/Dashboard/pages/Logs/Logs";
import Mentors from "./pages/Dashboard/pages/Mentors/Mentors";
import Leads from "./pages/Dashboard/pages/Leads/Leads";
import Profile from "./pages/Dashboard/pages/Profile/Profile";
import LandingPage from "./pages/Dashboard/pages/LandingPage/LandingPage";
import AcceptAproval from "./pages/Functional/AcceptAproval";
import Privacy from "./pages/Politiqueo/Privacy";
import Awards from "./pages/Dashboard/pages/Awards/Awards";
import PopupIframe from "./pages/PopupIframe/PopupIframe";
import Analysis from "./pages/Dashboard/pages/Analysis/Analysis";
import SuperIframe from "./pages/Dashboard/pages/Unigow/SuperIframe";
import AdminPage from "./pages/Dashboard/pages/Admin/Admin";
import AdminWhatsapps from "./pages/Dashboard/pages/Admin/pages/Whatsapps";
import AdminWhatsapp from "./pages/Dashboard/pages/Admin/pages/Whatsapp";
import Retos from "./pages/Dashboard/pages/Retos/Retos";
import AdminChats from "./pages/Dashboard/pages/Admin/pages/Chats";
import IframeWizard from "@unigow/pages/Dashboard/pages/Iframes/IframeWizard";
import IframeSelector from "@unigow/pages/Dashboard/pages/Iframes/IframeSelector";
import BasicConfig from "@unigow/pages/Dashboard/pages/Iframes/BasicConfig";
import ChatsPluginWizard from "@unigow/pages/Dashboard/pages/Iframes/tabs/Chatsplugin/ChatsPluginWizard";
import {IframeProvider} from "@unigow/pages/Iframe/contexts/IframeContext";
import Iframe from "@unigow/pages/Iframe/Iframe";
import LandingCreator from "@unigow/pages/Dashboard/pages/Iframes/tabs/Landingsplugin/LandingCreator";
import GroupPages from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/GroupPages";
import CardsPlugins from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/CardsPlugins";
import CancelActivity from "@unigow/pages/Functional/CancelActivity";
import Feedback from "@unigow/pages/Functional/Feedback";
import FillReto from "@unigow/pages/OrientatorRegister/FillReto";
import CardParticipants from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/CardParticipants";

export function App(): React.ReactElement {

    return (
        <Routes>
            <Route path="/" element={(
                <ProtectedLayout>
                    <Dashboard/>
                </ProtectedLayout>
            )}
            >
                <Route index element={<></>}/>
                <Route path="iframes" element={<Iframes/>}>
                    <Route index element={<IframeSelector/>}/>
                    <Route path=":iframeAlias" element={<IframeWizard/>}>
                        <Route index element={<BasicConfig/>}/>
                        <Route path="chats" element={<ChatsPluginWizard/>}/>
                        <Route path="groups" element={<GroupPages/>}/>
                        <Route path="landings" element={<LandingCreator/>}/>
                        <Route path="cards">
                            <Route index element={<CardsPlugins/>}/>
                            <Route path=":cardId" element={<CardsPlugins/>} />
                        </Route>
                    </Route>
                </Route>
                <Route path="chats" element={<ChatsPage/>}/>
                <Route path="stats" element={<Stats/>}/>
                <Route path="activity" element={<Logs/>}/>
                <Route path="leads" element={<Leads/>}/>
                <Route path="analysis" element={<Analysis/>}/>
                <Route path="account" element={<Profile/>}/>
                <Route path="ambassadors" element={<Mentors/>}/>
                <Route path="awards" element={<Awards/>}/>
                <Route path="global" element={<SuperIframe/>} />
                <Route path="retos" element={<Retos/>} />
                <Route path="admin" element={<AdminPage/>}>
                    <Route index element={<Navigate to="whatsapp"/>}/>
                    <Route path="whatsapp" element={<AdminWhatsapps/>}/>
                    <Route path="whatsapp/:id" element={<AdminWhatsapp/>}/>
                    <Route path="chats" element={<AdminChats/>}/>
                </Route>
                <Route path="*" element={<Navigate to={"/"}/>}/>
            </Route>
            {/* Vistas personalizadas */}
            <Route path="views" element={<ProtectedLayout/>} >
                <Route path="cards/:cardId/participants" element={<CardParticipants/>}/>
            </Route>
            <Route path="/completeregister" element={(
                <ProtectedLayout>
                    <OrientatorRegister/>
                </ProtectedLayout>
            )}
            />
            <Route path="/fillreto/:retoId" element={(
                <ProtectedLayout>
                    <FillReto/>
                </ProtectedLayout>
            )}
            />
            <Route path="/login" element={<Login/>}/>
            <Route path="/register">
                <Route path="ambassador" element={<Register userType="orientator"/>}/>
                <Route path="manager" element={<Register userType="manager"/>}/>
            </Route>
            <Route path="/iframe/:iframeId" element={(
                <IframeProvider>
                    <Iframe/>
                </IframeProvider>
            )}
            />
            {/* DEPRECAR */}
            <Route path="/iframe/:client/:iframe" element={(
                <IframeProvider>
                    <Iframe/>
                </IframeProvider>
            )}
            />
            <Route path="/popup/:popupId" element={(
                <PopupIframe/>
            )}
            />
            {/* DEPRECAR */}
            <Route path="/popup/:client/:popup" element={(
                <PopupIframe/>
            )}
            />
            <Route path="/landing/:client/:alias" element={<LandingPage/>}/>
            <Route path="/aproval" element={<AcceptAproval/>}/>
            <Route path="/leaveactivity" element={<CancelActivity/>}/>
            <Route path="/createfeedback" element={<Feedback/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
    );
}
